import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { useAuth } from 'src/context/auth-context.js'
import Case from 'src/pages/case.js'
import CaseDetail from 'src/pages/case.detail.js'
import Demo from 'src/pages/demo2.js'
import Login from 'src/pages/login'
import OnSiteSummary from 'src/pages/case.onsite.js'
import Profile from 'src/pages/profile'
import ResetPassword from 'src/pages/reset-password'
import Schedule from 'src/pages/schedule.js'
import SummaryNote from 'src/pages/summary-note'
import Video from 'src/pages/video'
import OnSite from 'src/pages/onsite'

import DemoMoss from 'src/pages/demo.moss.js'

// import Demo from 'src/Demo.js'

function RequireAuth({ children }) {
	let location = useLocation()
	const { user } = useAuth()
	if (!user?._id) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/login" state={{ from: location }} />
	}
	return children
}

function App() {
	// const { user } = useAuth()
	return (
		<Routes>
			<Route path="/login" element={<Login />} />
			<Route path="/demo" element={<Demo />} />
			<Route path="/demo-moss" element={<DemoMoss />} />
			<Route path="/reset-password" element={<ResetPassword />} />
			<Route
				path="/"
				element={
					<RequireAuth>
						<Schedule />
					</RequireAuth>
				}
			/>
			<Route
				path="/case"
				element={
					<RequireAuth>
						<Case />
					</RequireAuth>
				}
			/>
			<Route
				path="/onsite"
				element={
					<RequireAuth>
						<OnSite />
					</RequireAuth>
				}
			/>
			<Route
				path="/onsite/:id"
				element={
					<RequireAuth>
						<OnSiteSummary />
					</RequireAuth>
				}
			/>

			<Route
				path="/case/:id"
				element={
					<RequireAuth>
						<CaseDetail />
					</RequireAuth>
				}
			/>
			<Route
				path="/summary/:id"
				element={
					<RequireAuth>
						<SummaryNote />
					</RequireAuth>
				}
			/>
			<Route
				path="/profile"
				element={
					<RequireAuth>
						<Profile />
					</RequireAuth>
				}
			/>
			<Route
				path="/video"
				element={
					<RequireAuth>
						<Video />
					</RequireAuth>
				}
			/>
		</Routes>
	)
}

export default App

// <Route
// 				path="/protected"
// 				element={
// 					<RequireAuth>
// 						<Demo />
// 					</RequireAuth>
// 				}
// 			/>
