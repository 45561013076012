import Box from '@mui/material/Box'
import React, { useState, useEffect } from 'react'
import Layout from 'src/components/layout'
import { Manager } from 'socket.io-client'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'src/context/auth-context.js'
import get from 'lodash/get'

import OnSiteCaseCard from 'src/components/case/OnSiteCaseCard'

const ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT
const DOCTOR_KEY = process.env.REACT_APP_DOCTOR_KEY

function Case() {
	let navigate = useNavigate()
	const { user } = useAuth()
	const doctor_id = user?._id
	// eslint-disable-next-line
	const [_socket, setSocket] = useState()
	const [cases, setCases] = useState([])

	const onClick = (id) => () => navigate('/onsite/' + id)

	useEffect(() => {
		const manager = new Manager(ENDPOINT, {
			reconnectionDelayMax: 10000,
		})
		const socketOnsite = manager.socket('/doctor', {
			auth: {
				doctor_key: DOCTOR_KEY,
			},
		})
		// console.log(socketOnsite)

		if (socketOnsite) {
			socketOnsite.on('connect', () => {
				// console.log('connect', socketOnsite.connected)
				// console.log(socketOnsite.id)
				socketOnsite.emit('join', doctor_id)
				setSocket(socketOnsite)
			})
			socketOnsite.on('message', (data) => {
				// console.log('message', data)
			})
			socketOnsite.on('onsite:list', ({ onside_consults, sid }) => {
				if (sid === socketOnsite.id) {
					// console.log('🚀 ~ file: App.js ~ line 32 ~ socketOnsite.on ~ cases', onside_consults)
					setCases(onside_consults)
					// console.log('🚀 ~ file: App.js ~ line 27 ~ socketOnsite.on ~ sid', sid)
				}
			})
			socketOnsite.on('onsite:new', ({ onside_consult, sid }) => {
				setCases([onside_consult, ...cases])
				// console.log('onsite:new', onside_consult)
			})
			socketOnsite.on('disconnect', () => {
				// console.log('disconnect', socketOnsite.connected)
				// console.log(socketOnsite.id) // undefined
			})
		}
		// CLEAN UP THE EFFECT
		return () => socketOnsite.disconnect()
		// WARNING: react-hooks/exhaustive-deps
		// eslint-disable-next-line
	}, [])

	return (
		<Layout>
			<Box p={4}>
				<Box mb={5}>
					<Box fontSize={14}>List</Box>
					<Box fontSize={24}>Your Case</Box>
				</Box>
				<Box mb={3}>
					<Box>All Case({cases.length})</Box>
				</Box>
				{cases.map((row) => {
					const first_name = get(row, 'patient.first_name')
					const last_name = get(row, 'patient.last_name')
					const gender = get(row, 'patient.gender')
					const symptom = get(row, 'symptom')
					const birth_date = get(row, 'patient.birth_date')

					return (
						<Box key={row._id} mb={1} px={3} py={2.5} borderRadius="16px" border="1px solid #D8D8D8" display="flex">
							<OnSiteCaseCard
								first_name={first_name}
								last_name={last_name}
								birth_date={birth_date}
								gender={gender}
								symptom={symptom}
								onClick={onClick(row._id)}
							/>
						</Box>
					)
				})}
			</Box>
		</Layout>
	)
}

export default Case
