import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import React from 'react'
import MaleIcon from '@mui/icons-material/Male'
import FemaleIcon from '@mui/icons-material/Female'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

function getAge(dateString) {
	var today = new Date()
	var birthDate = new Date(dateString)
	var age = today.getFullYear() - birthDate.getFullYear()
	var m = today.getMonth() - birthDate.getMonth()
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--
	}
	return age
}

function Card(props) {
	const first_name = props.first_name
	const last_name = props.last_name
	const gender = props.gender
	const symptom = props.symptom
	const birth_date = props.birth_date

	const onClick = props.onClick
	const disabled = props.disabled || false

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs={12} md={4}>
				<Box display="flex" alignItems="center">
					<Box ml={2}>
						<Box>
							{first_name} {last_name}
						</Box>
						<Box fontSize={12} color="text.secondary">
							{gender === 'male' && <MaleIcon sx={{ mb: -1, mr: 1 }} />}
							{gender === 'female' && <FemaleIcon sx={{ mb: -1, mr: 1 }} />}
							<span style={{ textTransform: 'capitalize' }}>{gender}</span>
							<Box component="span" ml={2}>
								{getAge(birth_date)}y
							</Box>
						</Box>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				{symptom}
			</Grid>
			<Grid item xs={12} md={2} align="center">
				<Button disabled={disabled} onClick={onClick}>
					Detail <ArrowForwardIcon sx={{ ml: 1 }} />
				</Button>
			</Grid>
		</Grid>
	)
}

export default Card
