import { Appointments } from '@devexpress/dx-react-scheduler-material-ui'
import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui'
import { DateNavigator } from '@devexpress/dx-react-scheduler-material-ui'
import { CurrentTimeIndicator } from '@devexpress/dx-react-scheduler'
import { Resources } from '@devexpress/dx-react-scheduler-material-ui'
import { Scheduler } from '@devexpress/dx-react-scheduler-material-ui'
import { EditingState } from '@devexpress/dx-react-scheduler'
import { IntegratedEditing } from '@devexpress/dx-react-scheduler'
import { teal, pink } from '@mui/material/colors'
import { TodayButton } from '@devexpress/dx-react-scheduler-material-ui'
import { Toolbar } from '@devexpress/dx-react-scheduler-material-ui'
import { ViewState } from '@devexpress/dx-react-scheduler'
import { WeekView } from '@devexpress/dx-react-scheduler-material-ui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import React, { useState, useEffect } from 'react'
import { useSchedule } from 'src/context/schedule-context.js'
import Layout from 'src/components/layout'
import AddScheduleModal from 'src/components/schedule/addScheduleModal.js'
import moment from 'moment'
import PreviewIcon from '@mui/icons-material/Preview'
import { ConfirmationDialog } from '@devexpress/dx-react-scheduler-material-ui'
import CircularProgress from '@mui/material/CircularProgress'

const createAppointments = (appointments) =>
	appointments.map((appointment) => ({
		title: appointment.book ? `${appointment.book_by?.first_name} ${appointment.book_by?.last_name}` : 'Available',
		startDate: appointment.startDate,
		endDate: appointment.endDate,
		id: appointment._id,
		status: appointment.book ? 'booked' : 'wait',
		complete: appointment.complete,
		link: appointment.link,
	}))

// Tooltip Header
const ToolTipHeader = ({ children, appointmentData, ...restProps }) => {
	const appointmentHasStarted = moment().diff(moment(appointmentData.startDate), 'minutes') >= 0
	return (
		<AppointmentTooltip.Header {...restProps} appointmentData={appointmentData} showDeleteButton={!appointmentHasStarted && appointmentData.status === 'wait'}>
			<IconButton disabled={appointmentData.status !== 'booked' || appointmentData.complete} href={appointmentData.link} target="_blank" size="large">
				<PreviewIcon />
			</IconButton>
		</AppointmentTooltip.Header>
	)
}

function DoctorSchedule() {
	//////// resources
	const resources = [
		{
			fieldName: 'status',
			title: 'Status',
			instances: [
				{ id: 'wait', text: 'Available', color: teal },
				{ id: 'booked', text: 'Appointment', color: pink[200] },
			],
		},
	]

	//////// add schedule logics
	const [addScheduleData, setAddScheduleData] = useState({
		open: false,
		startDate: null,
	})
	const onOpenAddSchedule = (startDate = null) => {
		setAddScheduleData({ open: true, startDate })
	}
	const onCloseAddSchedule = () => {
		setAddScheduleData({ open: false, startDate: null })
	}

	////////////////////// schedule logics
	const { schedule, getSchedule, deleteSchedule, fetching } = useSchedule()
	const [currentDate, setCurrentDate] = useState(Date.now())

	const fetchSchedule = async () => {
		try {
			await getSchedule(moment(currentDate).format('yyyy-MM-DD'))
		} catch (error) {}
	}

	const onDelete = async (_id) => {
		try {
			await deleteSchedule(_id)
			await getSchedule(moment(currentDate).format('yyyy-MM-DD'))
		} catch (error) {}
	}

	const commitChanges = ({ added, changed, deleted }) => {
		if (deleted) {
			onDelete(deleted)
		}
	}

	const TimeTableCell = (props) => {
		const { startDate } = props

		return (
			<WeekView.TimeTableCell
				{...props}
				// open add modal
				onDoubleClick={() => onOpenAddSchedule(startDate)}
			/>
		)
	}

	// fetch list
	useEffect(() => {
		fetchSchedule()
		return () => {}
		// WARNING: react-hooks/exhaustive-deps
		// eslint-disable-next-line
	}, [currentDate])

	// rearrange data
	const appointment = createAppointments(schedule || [])
	const tableHeight = window.innerHeight - 250 > 400 ? window.innerHeight - 250 : 400

	return (
		<Layout>
			<AddScheduleModal open={addScheduleData.open} defaultData={addScheduleData.startDate} onClose={onCloseAddSchedule} refetch={fetchSchedule} />
			<Box p={4}>
				<Box fontSize={24} mb={1}>
					Schedule
					{fetching && <CircularProgress thickness={5} sx={{ ml: 2, mb: -1.5 }} />}
				</Box>
				<Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
					<Box>Calendar Summary</Box>
					<Button onClick={() => onOpenAddSchedule()} variant="contained" size="medium" sx={{ width: 200 }}>
						Create Slot
					</Button>
				</Box>
				<Paper>
					<Scheduler data={appointment} height={tableHeight}>
						<ViewState currentDate={currentDate} onCurrentDateChange={(currentDate) => setCurrentDate(currentDate)} />
						<EditingState onCommitChanges={commitChanges} />
						<IntegratedEditing />
						<WeekView startDayHour={0} endDayHour={24} cellDuration={20} timeTableCellComponent={TimeTableCell} />
						<Toolbar />
						<DateNavigator />
						<ConfirmationDialog />
						<TodayButton />
						<Appointments />
						<Resources data={resources} mainResourceName={'status'} />
						<AppointmentTooltip headerComponent={ToolTipHeader} />
						<CurrentTimeIndicator shadePreviousCells shadePreviousAppointments updateInterval={10000} />
					</Scheduler>
				</Paper>
			</Box>
		</Layout>
	)
}

export default DoctorSchedule
