import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import React from 'react'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

function SupplyCard(props) {
	const { name, description, onSelect, disabled } = props
	const matches = useMediaQuery((theme) => theme.breakpoints.up('lg'))

	return (
		<React.Fragment>
			<Grid item xs={12} lg={9}>
				<Typography variant="body1" gutterBottom component="div">
					{name}
				</Typography>
				<Typography variant="body2" gutterBottom component="div">
					{description}
				</Typography>
			</Grid>
			<Grid item xs={12} lg={2}>
				<Button variant="text" fullWidth={matches} onClick={onSelect} disabled={disabled}>
					{disabled ? 'Selected' : 'Select'}
				</Button>
			</Grid>
		</React.Fragment>
	)
}

SupplyCard.defaultProps = {
	name: '',
	description: '',
	disabled: false,
	onSelect: () => {},
}

export default SupplyCard
