import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import Axios from 'src/Axios'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import get from 'lodash/get'
import Layout from 'src/components/layout'
import Paper from '@mui/material/Paper'
import PatientDetail from 'src/components/case/PatientDetail'
import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import MasksIcon from '@mui/icons-material/Masks'
import TimelineIcon from '@mui/icons-material/Timeline'
import WarningIcon from '@mui/icons-material/Warning'
import ReportIcon from '@mui/icons-material/Report'
import HealingIcon from '@mui/icons-material/Healing'
import MedicationIcon from '@mui/icons-material/Medication'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import ErrorSnackbar from 'src/components/common/Snackbar'
import moment from 'moment'

const styleProps = {
	icon: { sx: { mr: 1, mb: -1, color: 'rgba(0, 0, 0, 0.5)' } },
}

function CaseDetail() {
	let location = useLocation()
	const navigate = useNavigate()
	const [detail, setDetail] = useState({})
	const [loadDetail, setLoadDetail] = useState(false)
	const [errorText, setErrorText] = useState(null)
	let id = location.pathname.split('/')[2]

	const fetchDetail = async () => {
		setLoadDetail(true)
		try {
			const res = await Axios({
				method: 'get',
				url: `api/appointment/` + id,
			})
			setDetail(res.data?.appointment)
		} catch (error) {}
		setLoadDetail(false)
	}

	useEffect(() => {
		fetchDetail()
		return () => {
			setDetail({})
		}

		// WARNING: react-hooks/exhaustive-deps
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		const interval = setInterval(() => setCurrentTime(moment()), 1000)
		return () => {
			clearInterval(interval)
		}
		// WARNING: react-hooks/exhaustive-deps
		// eslint-disable-next-line
	}, [])
	/// กดเข้าห้อง
	const onClick = async () => {
		try {
			const response = await Axios({
				method: 'post',
				url: `api/appointment/${id}/video-call`,
			})
			const room_key = get(response, 'data.appointment.room.api_key', '')
			// console.log('🚀 ~ file: case.detail.js ~ line 45 ~ onClick ~ room_key', room_key)
			const room_id = get(response, 'data.appointment.room.session_id', '')
			// console.log('🚀 ~ file: case.detail.js ~ line 46 ~ onClick ~ room_id', room_id)
			const session_id = get(response, 'data.appointment.room.provider_token', '')
			// console.log('🚀 ~ file: case.detail.js ~ line 47 ~ onClick ~ session_id', session_id)
			navigate({
				pathname: '/video',
				replace: true,
				search: `?${createSearchParams({
					room_key,
					room_id,
					session_id,
				})}`,
			})
		} catch (error) {
			// console.log(error.response)
			setErrorText('Error: Out of time')
		}
	}

	const toSummary = () => {
		navigate({ pathname: '/summary/' + id })
	}
	console.log(detail)
	const status = detail?.status
	const [currentTime, setCurrentTime] = useState(moment())
	const btnDisable = status === 'wait_confirm' || status === 'booking' ? false : true

	return (
		<Layout>
			<ErrorSnackbar errorText={errorText} onClose={() => setErrorText(null)} />
			<Box p={4}>
				<Box mb={5}>
					<Breadcrumbs aria-label="breadcrumb">
						<Link underline="hover" color="inherit" href="/case">
							Case
						</Link>
						<Typography color="text.primary">Doctor Room</Typography>
					</Breadcrumbs>
					<Box fontSize={24}>Appointment Detail</Box>
				</Box>
				<Box>
					<PatientDetail
						isActive={true}
						disabled={btnDisable}
						currentTime={currentTime}
						{...detail}
						buttonTitle={status === 'wait_confirm' ? 'SUMMARIZE' : 'JOIN'}
						onClick={status === 'wait_confirm' ? toSummary : onClick}
					/>
				</Box>
				<Divider sx={{ my: 2 }} />
				<Tabs value={0}>
					<Tab label="Medical Report" />
					<Tab label="History" />
				</Tabs>
				<Paper sx={{ px: 4, py: 3, mt: 1, fontSize: 14 }}>
					<Stack spacing={4}>
						<Box display="flex">
							<Box minWidth="190px">
								<MasksIcon {...styleProps.icon} />
								อาการ:
							</Box>
							<Box color="text.secondary">{loadDetail ? <Skeleton variant="text" width="180px" /> : get(detail, 'symptom')}</Box>
						</Box>
						<Box display="flex">
							<Box minWidth="190px">
								<TimelineIcon {...styleProps.icon} />
								ระยะเวลาที่เป็น:
							</Box>
							<Box color="text.secondary">{loadDetail ? <Skeleton variant="text" width="180px" /> : get(detail, 'duration')}</Box>
						</Box>
						<Box display="flex">
							<Box minWidth="190px">
								<WarningIcon {...styleProps.icon} />
								ยาที่ทานไปแล้ว:
							</Box>
							<Box color="text.secondary">{loadDetail ? <Skeleton variant="text" width="180px" /> : get(detail, 'medication_used')}</Box>
						</Box>
						<Box display="flex">
							<Box minWidth="190px">
								<HealingIcon {...styleProps.icon} />
								โรคประจำตัว:
							</Box>
							<Box color="text.secondary">
								{loadDetail ? <Skeleton variant="text" width="180px" /> : get(detail, 'person.health_profile.congenital_disease')}
							</Box>
						</Box>
						<Box display="flex">
							<Box minWidth="190px">
								<ReportIcon {...styleProps.icon} />
								การแพ้ยา:
							</Box>
							<Box color="text.secondary">
								{loadDetail ? <Skeleton variant="text" width="180px" /> : get(detail, 'person.health_profile.allergy', []).join(', ')}
							</Box>
						</Box>
						<Box display="flex">
							<Box minWidth="190px">
								<MedicationIcon {...styleProps.icon} />
								ยาที่ทานประจำ:
							</Box>
							<Box color="text.secondary">{loadDetail ? <Skeleton variant="text" width="180px" /> : get(detail, 'person.health_profile.medication_used')}</Box>
						</Box>
						<Box display="flex">
							<Box minWidth="190px">
								<InsertDriveFileIcon {...styleProps.icon} />
								อื่นๆ:
							</Box>
							<Box color="text.secondary">
								{loadDetail ? (
									<Skeleton variant="text" width="180px" />
								) : (
									<Box>
										{get(detail, 'person.health_profile.alcohol') && (
											<>
												<CheckCircleIcon sx={{ mb: -1, ml: 1, color: 'primary.main' }} /> ดื่มเหล้า
											</>
										)}
										{get(detail, 'person.health_profile.smoking') && (
											<>
												<CheckCircleIcon sx={{ mb: -1, ml: 1, color: 'primary.main' }} /> สูบบุหรี่
											</>
										)}
										{get(detail, 'person.health_profile.pregnant') && (
											<>
												<CheckCircleIcon sx={{ mb: -1, ml: 1, color: 'primary.main' }} /> ตั้งครรถ์
											</>
										)}
										{get(detail, 'person.health_profile.breast_feeding') && (
											<>
												<CheckCircleIcon sx={{ mb: -1, ml: 1, color: 'primary.main' }} /> ให้นมบุตร
											</>
										)}
									</Box>
								)}
							</Box>
						</Box>
					</Stack>
				</Paper>
			</Box>
		</Layout>
	)
}

export default CaseDetail
