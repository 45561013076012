import * as auth from 'src/utils/auth-client.js'
import axios from 'src/Axios/index.js'
import get from 'lodash/get'

async function bootstrapAppData() {
	let user = null
	try {
		const token = await auth.getAccessToken()
		if (token) {
			// COMMENT: CALL API
			const response = await axios({
				method: 'GET',
				url: `api/user/profile`,
			})
			// COMMENT: RETURN USER PROFILE
			user = response.data.profile
		}
	} catch (error) {
		// COMMENT: ต้องไป login ใหม่เสมอ
		if (get(error, 'response.data.code') === 401) {
			// actually do nothing!
		}
	}
	return user
}

export { bootstrapAppData }
