import { alpha } from '@mui/material/styles'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useTheme, styled } from '@mui/material/styles'
import { VariableSizeList } from 'react-window'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import Avatar from '@mui/material/Avatar'
import axios from 'src/Axios'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FemaleIcon from '@mui/icons-material/Female'
import get from 'lodash/get'
import Grid from '@mui/material/Grid'
import join from 'lodash/join'
import Layout from 'src/components/layout'
import Link from '@mui/material/Link'
import LoadingButton from '@mui/lab/LoadingButton'
import MedicineCard from 'src/components/summary-note/medicine-card'
import MenuItem from '@mui/material/MenuItem'
import moment from 'moment-timezone'
import Popper from '@mui/material/Popper'
import React, { useState, useEffect, useCallback, Fragment } from 'react'
import Skeleton from '@mui/material/Skeleton'
import SupplyCard from 'src/components/summary-note/supply-card'
import TextField from '@mui/material/TextField'
import toNumber from 'lodash/toNumber'
import Typography from '@mui/material/Typography'
import upperFirst from 'lodash/upperFirst'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Paper } from '@mui/material'
import isEmpty from 'lodash/isEmpty'

const LISTBOX_PADDING = 8 // px

const defaultValues = {
	name: '',
	amount: 0,
	presentation: '-',
	unit_amount: 0,
	unit: '-',
	medication_unit: '-',
	frequency: '',
	other_recomendation: '',
}

const supply_default = {
	supply: '',
	presentation_quantity: 0,
	presentation_name: '-',
	presentation_unit_quantity: 0,
	presentation_unit: '-',
	indication: '',
	administration: '',
}

function renderRow(props) {
	const { data, index, style } = props
	const dataSet = data[index]
	const inlineStyle = {
		...style,
		top: style.top + LISTBOX_PADDING,
	}

	return (
		<Typography noWrap style={inlineStyle}>
			{dataSet}
		</Typography>
	)
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext)
	return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data) {
	const ref = React.useRef(null)
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true)
		}
	}, [data])
	return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
	const { children, ...other } = props
	const itemData = []
	children.forEach((item) => {
		itemData.push(item)
		itemData.push(...(item.children || []))
	})

	const theme = useTheme()
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
		noSsr: true,
	})

	const itemCount = itemData.length
	const itemSize = smUp ? 36 : 48

	const getChildSize = (child) => {
		if (child.hasOwnProperty('group')) {
			return 48
		}

		return itemSize
	}

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
	}

	const gridRef = useResetCache(itemCount)

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={(index) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	)
})

const StyledPopper = styled(Popper)({
	[`& .${autocompleteClasses.listbox}`]: {
		boxSizing: 'border-box',
		'& ul': {
			padding: 0,
			margin: 0,
		},
	},
})

function SummaryNote() {
	const navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	// eslint-disable-next-line
	const {
		watch,
		register,
		setValue,
		handleSubmit,
		control,
		reset,
		// formState: { errors },
	} = useForm({
		defaultValues,
	})
	// eslint-disable-next-line
	const { register: register2, setValue: setValue2, handleSubmit: handleSubmit2, control: control2, reset: reset2 } = useForm({})
	const {
		watch: watch3,
		register: register3,
		setValue: setValue3,
		handleSubmit: handleSubmit3,
		reset: reset3,
		// formState: { errors: errors3 },
	} = useForm({ defaultValues: supply_default })

	let params = useParams()
	const id = params.id

	//Set Current Medicine
	const [currentMedicine, setCurrentMedicine] = useState({})

	// Max Allowance
	const [maxAllowance, setMaxAllowance] = useState('0.00')

	// ==== START supply section ====
	const [showSupplyModal, setShowSupplyModal] = useState(false)
	const [supplySubmitting, setSupplySubmitting] = useState(false)
	const { presentation_name, presentation_unit } = watch3()
	// Dropdown Preparing
	const [presentationDataSupply, setPresentationDataSupply] = useState([])
	// ==== END supply section ====

	const [initialForm, setInitialForm] = useState(true)
	const [showModal, setShowModal] = useState(false)
	const [loadingMedication, setLoadingMedication] = useState(false)
	const [loadingTMTData, setloadingTMTData] = useState(false)
	const [loadingSupply, setLoadingSupply] = useState(false)
	const [submittingApprove, setSubmittingApprove] = useState(false)
	// eslint-disable-next-line
	const { name, presentation, unit, medication_unit, frequency } = watch()
	const [submitting, setSubmitting] = useState(false)

	// Dropdown Preparing
	const [medicineData, setMedicineData] = useState([])
	const [presentationData, setPresentationData] = useState([])
	const [administrationUnit, setAdministrationUnit] = useState([])
	const [frequencyData, setFrequencyData] = useState([])

	// for label to show (fullname, profileImg, gender, )
	const [fullname, setFullname] = useState('')
	const [gender, setGender] = useState('')
	const [date, setDate] = useState('')
	const [timeSlot, setTimeSlot] = useState('')
	const [medicationData, setMedicationData] = useState([])
	const [supplyData, setSupplyData] = useState([])

	const onApprove = async (data, event) => {
		event.preventDefault()
		try {
			setSubmittingApprove(true)
			await axios({
				method: 'put',
				url: `api/appointment/${id}/summarize`,
				data,
			})
			setSubmittingApprove(false)
			navigate({ pathname: '/case', replace: true })
		} catch (error) {
			// mission action?
		}
	}

	// ==== START Supply Modal Funtion ====
	const handleAddSupply = () => {
		setShowSupplyModal((prev) => !prev)
	}

	const handleChangePresentationNameSupply = (e) => {
		setValue3('presentation_name', e.target.value)
	}

	const handleChangePresentationUnitSupply = (e) => {
		setValue3('presentation_unit', e.target.value)
	}

	const onSubmitSupply = async (data, event) => {
		setSupplySubmitting(true)
		const submitData = {
			administration: get(data, 'administration', ''),
			indication: get(data, 'indication', ''),
			presentation_name: get(data, 'presentation_name', ''),
			presentation_quantity: toNumber(get(data, 'presentation_quantity', '')),
			presentation_unit: get(data, 'presentation_unit', ''),
			presentation_unit_quantity: toNumber(get(data, 'presentation_unit_quantity', '')),
			supply: get(data, 'supply', ''),
		}
		try {
			setLoadingSupply(true)
			await axios({
				method: 'post',
				url: `api/appointment/${id}/supply`,
				data: submitData,
			})
			const resp = await axios({ method: 'get', url: `api/appointment/${id}/supply` })
			const supply = get(resp, 'data.appointment_supply', [])
			setSupplyData(supply)
			setSupplySubmitting(false)
			reset3()
			setShowSupplyModal(false)
			enqueueSnackbar('Data entered successfully', { variant: 'success' })
		} catch (error) {
			// handle err here.
			setSupplySubmitting(false)
			enqueueSnackbar('Some information is missing, please check and try again', { variant: 'error' })
		} finally {
			setLoadingSupply(false)
		}
	}

	const handleCancelAddSupply = () => {
		reset3()
		setShowSupplyModal(false)
	}

	// ==== END Supply Modal Funtion ====

	const handleAddmedicine = () => {
		setShowModal(true)
	}

	const handleChangePresentation = (e) => {
		setValue('presentation', e.target.value)
	}

	const handleChangeUnit = (e) => {
		setValue('unit', e.target.value)
	}

	const handleChangeMedicationUnit = (e) => {
		setValue('medication_unit', e.target.value)
	}

	const handleFrequencyOptionClick = (text) => () => {
		const nextFrequency = frequency + ` ${text}`
		setValue('frequency', nextFrequency)
	}

	const handleCancelAddMedicine = () => {
		reset()
		setShowModal(false)
	}

	const handleRemoveMedicineById = useCallback(
		(medId) => async () => {
			try {
				await axios({
					method: 'delete',
					url: `api/appointment/${id}/medicine/${medId}`,
				})
				setLoadingMedication(true)
				// reload medicine also need to have loading
				const resp = await axios({ method: 'get', url: `api/appointment/${id}/medicine` })
				const medicine = get(resp, 'data.appointment_medicine', [])
				setMedicationData(medicine)
			} catch (error) {
				// COMMENT: เวลาพัง ไม่ต้อง Reload
				// loadingMedication
			} finally {
				setLoadingMedication(false)
			}
		},
		[id]
	)

	const handleRemoveSupplyById = useCallback(
		(supId) => async () => {
			try {
				setLoadingSupply(true)
				await axios({
					method: 'delete',
					url: `api/appointment/${id}/supply/${supId}`,
				})
				// reload medicine also need to have loading
				const resp = await axios({ method: 'get', url: `api/appointment/${id}/supply` })
				const supply = get(resp, 'data.appointment_supply', [])
				setSupplyData(supply)
			} catch (error) {
				// missing actions ?
			} finally {
				setLoadingSupply(false)
			}
		},
		[id]
	)

	const onSubmit = async (data, event) => {
		setSubmitting(true)
		const submitData = {
			medicine: get(data, 'name._id', ''),
			presentation_quantity: toNumber(get(data, 'amount', '')),
			presentation_name: get(data, 'presentation', ''),
			presentation_unit_quantity: toNumber(get(data, 'unit_amount', '')),
			presentation_unit: get(data, 'unit', ''),
			indication: get(data, 'indication', ''),
			administration: get(data, 'administration', ''),
			administration_amount: toNumber(get(data, 'medication_amount', '')),
			administration_unit: get(data, 'medication_unit', ''),
			frequency: get(data, 'frequency', ''),
			recommendation: get(data, 'other_recomendation', ''),
		}
		try {
			setLoadingMedication(true)
			await axios({
				method: 'post',
				url: `api/appointment/${id}/medicine`,
				data: submitData,
			})
			const resp = await axios({ method: 'get', url: `api/appointment/${id}/medicine` })
			const medicine = get(resp, 'data.appointment_medicine', [])
			setMedicationData(medicine)
			setSubmitting(false)
			reset()
			setCurrentMedicine({})
			setShowModal(false)
			enqueueSnackbar('Data entered successfully', { variant: 'success' })
		} catch (error) {
			// handle err here.
			setSubmitting(false)
			enqueueSnackbar('Some information is missing, please check and try again', { variant: 'error' })
		} finally {
			setLoadingMedication(false)
		}
	}

	// ==== START Supply Effect ====
	useEffect(() => {
		register3('presentation_name', { required: true, message: 'This field is required' })
		register3('presentation_unit')
	}, [register3])

	useEffect(() => {
		async function initialPresentationDropdownSupply() {
			const resp = await axios({ method: 'get', url: `api/presentation-name` })
			const data = get(resp, 'data.presentation_name', [])
			setPresentationDataSupply(data)
		}
		initialPresentationDropdownSupply()
	}, [])
	// ==== END Supply Effect ====

	useEffect(() => {
		register('presentation', { required: true, message: 'This field is required' })
		register('unit')
		register('medication_unit')
	}, [register])

	// load medicine
	useEffect(() => {
		async function initialMedicineData(caseId) {
			try {
				setLoadingMedication(true)
				const resp = await axios({ method: 'get', url: `api/appointment/${caseId}/medicine` })
				const medicine = get(resp, 'data.appointment_medicine', [])
				setMedicationData(medicine)
			} catch (error) {
				// what is failed to load action ?
			} finally {
				setLoadingMedication(false)
			}
		}
		initialMedicineData(id)
	}, [id])

	// load supply
	useEffect(() => {
		// first
		async function initialSupplyData(caseId) {
			try {
				setLoadingSupply(true)
				const resp = await axios({ method: 'get', url: `api/appointment/${caseId}/supply` })
				const supply = get(resp, 'data.appointment_supply', [])
				setSupplyData(supply)
			} catch (error) {
				// what is failed to load action ?
			} finally {
				setLoadingSupply(false)
			}
		}
		initialSupplyData(id)
	}, [id])

	// Load Medicine/Tradename Select Data
	useEffect(() => {
		setloadingTMTData(true)
		async function initialMedicineDropdown() {
			const resp = await axios({ method: 'get', url: `api/medicine` })
			const data = get(resp, 'data.medicines', [])
			setMedicineData(data)
			setloadingTMTData(false)
		}
		initialMedicineDropdown()
	}, [])

	useEffect(() => {
		async function initialPresentationDropdown() {
			const resp = await axios({ method: 'get', url: `api/presentation-name` })
			const data = get(resp, 'data.presentation_name', [])
			setPresentationData(data)
		}
		initialPresentationDropdown()
	}, [])

	useEffect(() => {
		async function initialAdministrationUnitDropdown() {
			const resp = await axios({ method: 'get', url: `api/administration-unit` })
			const data = get(resp, 'data.administration_unit', [])
			setAdministrationUnit(data)
		}
		initialAdministrationUnitDropdown()
	}, [])

	useEffect(() => {
		async function initialFrequencyHintOption() {
			const resp = await axios({ method: 'get', url: `api/frequency-options` })
			const data = get(resp, 'data.frequency_options', [])
			setFrequencyData(data)
		}
		initialFrequencyHintOption()
	}, [])

	useEffect(() => {
		async function initialForm(caseId) {
			try {
				// set form loading = true
				setInitialForm(true)
				if (caseId) {
					const resp = await axios({ method: 'get', url: `api/appointment/${caseId}` })
					const rx_max_allowance = get(resp, 'data.appointment.rx_max_allowance', 0)
						.toFixed(2)
						.toString()
						.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
					const first_name = get(resp, 'data.appointment.person.first_name', '')
					const last_name = get(resp, 'data.appointment.person.last_name', '')
					const fullname = `${first_name} ${last_name}`
					const gender = upperFirst(get(resp, 'data.appointment.person.gender', ''))
					const start_date = get(resp, 'data.appointment.telehealth_slot.start_date', '')
					const _date = moment(start_date).tz('Asia/Bangkok').format('MMMM DD, YYYY')
					const end_date = get(resp, 'data.appointment.telehealth_slot.end_date', '')
					const start_time = moment(start_date).tz('Asia/Bangkok').format('HH:mm')
					const end_time = moment(end_date).tz('Asia/Bangkok').format('HH:mm')
					const start_end_time = `${start_time}-${end_time}`
					const summary_note = get(resp, 'data.appointment.summary_note', '')
					const patient_recommendation = get(resp, 'data.appointment.patient_recommendation', '')
					const medical_recommendation = get(resp, 'data.appointment.medical_recommendation', '')
					const diagnosis = get(resp, 'data.appointment.diagnosis', '')
					const symptom = get(resp, 'data.appointment.symptom', '')
					const duration = get(resp, 'data.appointment.duration', '')
					const medication_used = get(resp, 'data.appointment.medication_used', '')
					const congenital_disease = get(resp, 'data.appointment.person.health_profile.congenital_disease', '')
					const _allergy = get(resp, 'data.appointment.person.health_profile.allergy', '')
					const allergy_txt = join(_allergy, ',')
					const frequently_medication_used = get(resp, 'data.appointment.person.health_profile.medication_used', '')
					const smoking = get(resp, 'data.appointment.person.health_profile.smoking', false)
					const pregnant = get(resp, 'data.appointment.person.health_profile.pregnant', false)
					// set value
					setMaxAllowance(rx_max_allowance)
					setFullname(fullname)
					setGender(gender)
					setDate(_date)
					setTimeSlot(start_end_time)
					setValue2('summary_note', summary_note)
					setValue2('patient_recommendation', patient_recommendation)
					setValue2('medical_recommendation', medical_recommendation)
					setValue2('diagnosis', diagnosis)
					setValue2('symptom', symptom)
					setValue2('duration', duration)
					setValue2('medication_used', medication_used)
					setValue2('congenital_disease', congenital_disease)
					setValue2('allergy', allergy_txt)
					setValue2('frequently_medication_used', frequently_medication_used)
					setValue2('smoking', smoking)
					setValue2('pregnant', pregnant)
				}
			} catch (error) {
			} finally {
				// set form loading = false
				setInitialForm(false)
			}
		}
		initialForm(id)
	}, [id, setInitialForm, setValue2])

	return (
		<Layout>
			<Box p={4}>
				<Box mb={1}>
					<Breadcrumbs aria-label="breadcrumb">
						<Link underline="hover" color="inherit" href="/case">
							Case
						</Link>
						<Link underline="hover" color="inherit" href={`/case/${id}`}>
							{id}
						</Link>
						<Typography color="text.primary">Summary</Typography>
					</Breadcrumbs>
				</Box>
				<Box mb={3}>
					<Typography variant="h5">Summary Note</Typography>
				</Box>
				<Box mb={1}>
					<Typography variant="body1">Patient</Typography>
				</Box>
				<Box mb={1} sx={{ flexGrow: 1 }}>
					<Grid container spacing={2}>
						<Grid item>
							{initialForm ? <Skeleton variant="circular" width={56} height={56} /> : <Avatar sx={{ width: 56, height: 56 }}>{fullname.charAt(0)}</Avatar>}
						</Grid>
						<Grid item>
							<Box sx={{ display: { xs: 'block', sm: 'none' } }}>
								{initialForm && (
									<Box>
										<Skeleton variant="text" />
										<Skeleton variant="rectangular" width={150} height={18} />
									</Box>
								)}
								{!initialForm && (
									<Fragment>
										<Typography variant="body1" display="block">
											{fullname}
										</Typography>
										<Box mb={1} sx={{ display: 'flex', alignItems: 'center' }}>
											<FemaleIcon sx={{ color: 'text.disabled', width: '18px', height: '18px' }} />
											<Typography variant="body2" color="text.disabled" component="span" ml={1}>
												{gender}
											</Typography>
										</Box>
										<Box mb={1} sx={{ display: 'flex', alignItems: 'center' }}>
											<CalendarTodayOutlinedIcon sx={{ color: 'text.disabled', width: '18px', height: '18px' }} />
											<Typography variant="body2" color="text.disabled" component="span" ml={1}>
												{date}
											</Typography>
										</Box>
										<Box mb={1} sx={{ display: 'flex', alignItems: 'center' }}>
											<AccessTimeOutlinedIcon sx={{ color: 'text.disabled', width: '18px', height: '18px' }} />
											<Typography variant="body2" color="text.disabled" component="span" ml={1}>
												{timeSlot}
											</Typography>
										</Box>
									</Fragment>
								)}
							</Box>
							<Box sx={{ display: { xs: 'none', sm: 'block' } }}>
								{initialForm && (
									<Box>
										<Skeleton variant="text" />
										<Skeleton variant="rectangular" width={150} height={18} />
									</Box>
								)}
								{!initialForm && (
									<Fragment>
										<Typography variant="body1" display="block">
											{fullname}
										</Typography>
										<Box sx={{ display: 'inline' }}>
											<FemaleIcon sx={{ color: 'text.disabled', width: '18px', height: '18px' }} />
											<Typography variant="body2" color="text.disabled" component="span" ml={1}>
												{gender}
											</Typography>
										</Box>
										<Box ml={1} sx={{ display: 'inline' }}>
											<CalendarTodayOutlinedIcon sx={{ color: 'text.disabled', width: '18px', height: '18px' }} />
											<Typography variant="body2" color="text.disabled" component="span" ml={1}>
												{date}
											</Typography>
										</Box>
										<Box ml={1} sx={{ display: 'inline' }}>
											<AccessTimeOutlinedIcon sx={{ color: 'text.disabled', width: '18px', height: '18px' }} />
											<Typography variant="body2" color="text.disabled" component="span" ml={1}>
												{timeSlot}
											</Typography>
										</Box>
									</Fragment>
								)}
							</Box>
						</Grid>
					</Grid>
				</Box>
				<form onSubmit={handleSubmit2(onApprove)}>
					<Box mt={3}>
						<Box mb={5}>
							<Grid container spacing={0}>
								<Grid item xs={12} md={3}>
									<Typography variant="body1" display="block" gutterBottom>
										Personal Note
									</Typography>
									<Typography variant="subtitle" color="text.disabled" display="block" gutterBottom>
										ข้อมูลนี้ถูกจัดเก็บไว้แบบส่วนตัว
									</Typography>
								</Grid>
								<Grid item xs={12} md={9}>
									<Box>
										<TextField
											{...register2('summary_note', { required: true })}
											// onChange={onChangeSummaryNote}
											id="personal-note-input"
											label="Note"
											fullWidth
											multiline
											minRows={2}
											maxRows={5}
											autoComplete="off"
											variant="standard"
											// disabled={initialForm}
											InputLabelProps={{ shrink: true }}
										/>
									</Box>
								</Grid>
							</Grid>
						</Box>
						<Box mb={1}>
							<Grid container spacing={0}>
								<Grid item xs={12} md={3}>
									<Typography variant="body1" display="block" gutterBottom>
										Case Summary
									</Typography>
								</Grid>
								<Grid item xs={12} md={9}>
									<Box mb={2}>
										<TextField
											{...register2('patient_recommendation', { required: true })}
											// onChange={onChangePatientRecommendation}
											id="patient-recommendation-input"
											label="Patient Recommendation"
											fullWidth
											variant="standard"
											sx={{ mb: 1.5 }}
											// disabled={initialForm}
											InputLabelProps={{ shrink: true }}
										/>
										<Typography variant="caption" display="block" color="text.disabled">
											This message will be displayed to patient after case ended.
										</Typography>
									</Box>
									<Box mb={2}>
										<TextField
											{...register2('medical_recommendation', { required: true })}
											// onChange={onChangeMedical}
											id="medical-recommendation-input"
											label="Medical Recommendation"
											fullWidth
											variant="standard"
											sx={{ mb: 1.5 }}
											// disabled={initialForm}
											InputLabelProps={{ shrink: true }}
										/>
										<Typography variant="caption" display="block" color="text.disabled">
											For next follow up, case referal.
										</Typography>
									</Box>
									<Box>
										<TextField
											{...register2('diagnosis', { required: true })}
											// onChange={onChangeDiagnosis}
											id="diagnosis-input"
											label="Consultation reports"
											fullWidth
											variant="standard"
											sx={{ mb: 1.5 }}
											// disabled={initialForm}
											InputLabelProps={{ shrink: true }}
										/>
										<Typography variant="caption" display="block" color="text.disabled">
											Diagnosis, a breif consultation summary.
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Box>
						<Box my={2}>
							<Divider />
						</Box>
						<Box mt={3} mb={4}>
							<Typography variant="body1" display="inline">
								Prescription
							</Typography>
							<Box mx={2} p={2} borderRadius={2} sx={{ display: 'inline', bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1) }}>
								วงเงินค่ายา {maxAllowance === '0.00' ? 'ไม่ระบุ' : `${maxAllowance} บาท`}
							</Box>
						</Box>
						<Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
							<Grid item>
								<Typography variant="body2" gutterBottom>
									Medicine
								</Typography>
								<Typography variant="caption" display="block" color="text.disabled">
									{medicationData.length} รายการ
								</Typography>
							</Grid>
							<Grid item>
								<Button variant="outlined" onClick={handleAddmedicine}>
									+ ADD MEDICINE
								</Button>
							</Grid>
							{loadingMedication ? (
								<Grid component={Box} my={2} item xs={12}>
									<Skeleton variant="rectangular" width="100%" height={100} />
								</Grid>
							) : (
								medicationData.map((record) => {
									//make data here
									const data = {
										trade_name: get(record, 'trade_name', ''),
										indication: get(record, 'indication', ''),
										administration: get(record, 'administration', ''),
										administration_amount: get(record, 'administration_amount', ''),
										administration_unit: get(record, 'administration_unit', ''),
										frequency: get(record, 'frequency', ''),
										recommendation: get(record, 'recommendation', ''),
										full_generics: get(record, 'full_generics', ''),
										presentation_quantity: get(record, 'presentation_quantity', ''),
										presentation_name: get(record, 'presentation_name', ''),
										presentation_unit_quantity: get(record, 'presentation_unit_quantity', ''),
										presentation_unit: get(record, 'presentation_unit', ''),
									}
									return (
										<Grid key={record._id} item xs={12}>
											<MedicineCard data={data} onRemoveClick={handleRemoveMedicineById(record._id)} />
										</Grid>
									)
								})
							)}
						</Grid>
						<Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center" mt={2}>
							<Grid item>
								<Typography variant="body2" gutterBottom>
									Supply
								</Typography>
								<Typography variant="caption" display="block" color="text.disabled">
									{supplyData.length} รายการ
								</Typography>
							</Grid>
							<Grid item>
								<Button variant="outlined" onClick={handleAddSupply}>
									+ ADD SUPPLY
								</Button>
							</Grid>
							{loadingSupply ? (
								<Grid component={Box} my={2} item xs={12}>
									<Skeleton variant="rectangular" width="100%" height={100} />
								</Grid>
							) : (
								supplyData.map((record) => {
									//make data here
									const data = {
										trade_name: get(record, 'trade_name', ''),
										administration: get(record, 'administration', ''),
										indication: get(record, 'indication', ''),
										presentation_name: get(record, 'presentation_name', ''),
										presentation_quantity: get(record, 'presentation_quantity', ''),
										presentation_unit: get(record, 'presentation_unit', ''),
										presentation_unit_quantity: get(record, 'presentation_unit_quantity', ''),
										supply: get(record, 'supply', ''),
									}
									return (
										<Grid key={record._id} item xs={12}>
											<SupplyCard data={data} onRemoveClick={handleRemoveSupplyById(record._id)} />
										</Grid>
									)
								})
							)}
						</Grid>
						<Box my={2}>
							<Divider />
						</Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<LoadingButton variant="contained" onClick={handleSubmit2(onApprove)} loading={submittingApprove}>
								Approve
							</LoadingButton>
						</Box>
					</Box>
				</form>
			</Box>
			<Box>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Dialog component="form" maxWidth="xl" fullWidth open={showModal} onClose={() => {}}>
						<DialogTitle>ADD MEDICINE</DialogTitle>
						<DialogContent>
							{isEmpty(currentMedicine) ? (
								<Typography variant="subtitle1" display="block" gutterBottom sx={{ color: 'text.disabled' }}>
									Select a medicine form Trad name / Generic name
								</Typography>
							) : (
								<Box mb={4}>
									<Typography variant="subtitle1" display="block" gutterBottom sx={{ color: 'text.disabled' }}>
										Selected Medicine
									</Typography>
									<Paper elevation={3} sx={{ p: 2, background: '#CCE6E6' }}>
										<Typography variant="body1" display="block" gutterBottom>
											{currentMedicine.full_generics}
										</Typography>
										<Typography variant="subtitle2" display="block" gutterBottom>
											{currentMedicine.dosage_form} ({currentMedicine.unit_of_use})
										</Typography>
									</Paper>
								</Box>
							)}
							<Box mt={2}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Controller
											name="name"
											control={control}
											rules={{ required: true }}
											render={({ field: { onChange, onBlur, value, name, ref } }) => (
												<Autocomplete
													loading={loadingTMTData}
													loadingText="Loading medicine..."
													options={medicineData}
													autoHighlight
													ListboxComponent={ListboxComponent}
													PopperComponent={StyledPopper}
													getOptionLabel={(option) => option.full_name}
													onChange={(event, newValue) => {
														// auto set value for other textfield
														setCurrentMedicine(newValue)
														setValue('indication', get(newValue, 'suggestion.indication', ''))
														setValue('administration', get(newValue, 'suggestion.administration', ''))
														setValue('frequency', get(newValue, 'suggestion.frequency', ''))
														setValue('other_recomendation', get(newValue, 'suggestion.recommendation', ''))
														onChange(newValue)
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															label="Tradename/Generic Name *"
															placeholder="Type some keyword"
															variant="outlined"
															helperText="Tradename might be changed by pharmacist, please check for generics and strength."
														/>
													)}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12} md={4}>
										<TextField
											{...register('amount', { required: true })}
											label="Amount *"
											type="number"
											InputLabelProps={{ shrink: true }}
											inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
											autoComplete="off"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={8}>
										<TextField label="Presentation *" id="select-presentation" value={presentation} onChange={handleChangePresentation} select fullWidth>
											<MenuItem value="-">
												<em>-</em>
											</MenuItem>
											{presentationData.map((record) => (
												<MenuItem key={record._id} value={record.name}>
													{record.name}
												</MenuItem>
											))}
										</TextField>
									</Grid>
									<Grid item xs={12} md={4}>
										<TextField
											{...register('unit_amount')}
											label="Amount"
											type="number"
											InputLabelProps={{ shrink: true }}
											inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
											autoComplete="off"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={8}>
										<TextField label="Unit" id="select-unit" value={unit} onChange={handleChangeUnit} select fullWidth>
											<MenuItem value="-">
												<em>-</em>
											</MenuItem>
											{presentationData.map((record) => (
												<MenuItem key={record._id} value={record.name}>
													{record.name}
												</MenuItem>
											))}
										</TextField>
									</Grid>
								</Grid>
								<Box mt={2}>
									<Typography variant="subtitle1" display="block" gutterBottom>
										Medication Uasge
									</Typography>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextField
												{...register('indication', { required: true })}
												label="Indication *"
												InputLabelProps={{ shrink: true }}
												fullWidth
												autoComplete="off"
												helperText="A medical condition that a medicine is used for."
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<TextField
												{...register('administration', { required: true, message: 'This field is required' })}
												label="Administration *"
												InputLabelProps={{ shrink: true }}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField
												{...register('medication_amount')}
												label="Amount"
												type="number"
												InputLabelProps={{ shrink: true }}
												inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
												autoComplete="off"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField label="Unit" id="select-medication-unit" value={medication_unit} onChange={handleChangeMedicationUnit} select fullWidth>
												<MenuItem value="-">
													<em>-</em>
												</MenuItem>
												{administrationUnit.map((record) => (
													<MenuItem key={record._id} value={record.name}>
														{record.name}
													</MenuItem>
												))}
											</TextField>
										</Grid>
										<Grid item xs={12}>
											<TextField {...register('frequency')} label="Frequency" InputLabelProps={{ shrink: true }} autoComplete="off" fullWidth />
											<Box mt={1}>
												{frequencyData.map((record) => (
													<Button key={record._id} onClick={handleFrequencyOptionClick(record.name)}>
														{record.name}
													</Button>
												))}
											</Box>
										</Grid>
										<Grid item xs={12}>
											<TextField {...register('other_recomendation')} label="Other Recomendation" InputLabelProps={{ shrink: true }} fullWidth />
										</Grid>
									</Grid>
								</Box>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleCancelAddMedicine}>Cancel</Button>
							<LoadingButton loading={submitting} type="submit">
								Add
							</LoadingButton>
						</DialogActions>
					</Dialog>
				</form>
			</Box>
			<Box>
				<form onSubmit={handleSubmit3(onSubmitSupply)}>
					<Dialog component="form" maxWidth="xl" fullWidth open={showSupplyModal} onClose={handleCancelAddSupply}>
						<DialogTitle>ADD MEDICAL SUPPLY</DialogTitle>
						<DialogContent>
							<Box mt={2}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<TextField
											{...register3('supply', { required: true })}
											label="Product Name *"
											InputLabelProps={{ shrink: true }}
											autoComplete="off"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={2}>
										<TextField
											{...register3('presentation_quantity', { required: true })}
											label="Amount *"
											type="number"
											InputLabelProps={{ shrink: true }}
											inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
											autoComplete="off"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={4}>
										<TextField
											label="Presentation *"
											id="select-presentation"
											value={presentation_name}
											onChange={handleChangePresentationNameSupply}
											select
											fullWidth
										>
											<MenuItem value="-">
												<em>-</em>
											</MenuItem>
											{presentationDataSupply.map((record) => (
												<MenuItem key={record._id} value={record.name}>
													{record.name}
												</MenuItem>
											))}
										</TextField>
									</Grid>
									<Grid item xs={12} md={2}>
										<TextField
											{...register3('presentation_unit_quantity', { required: true })}
											label="Amount"
											type="number"
											InputLabelProps={{ shrink: true }}
											inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
											autoComplete="off"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={4}>
										<TextField
											label="Presentation"
											id="select-presentation-unit"
											value={presentation_unit}
											onChange={handleChangePresentationUnitSupply}
											select
											fullWidth
										>
											<MenuItem value="-">
												<em>-</em>
											</MenuItem>
											{presentationDataSupply.map((record) => (
												<MenuItem key={record._id} value={record.name}>
													{record.name}
												</MenuItem>
											))}
										</TextField>
									</Grid>
								</Grid>
								<Box mt={2}>
									<Typography variant="subtitle1" display="block" gutterBottom>
										Medication Uasge
									</Typography>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextField
												{...register3('indication', { required: true })}
												label="Indication *"
												InputLabelProps={{ shrink: true }}
												fullWidth
												autoComplete="off"
												helperText="A medical condition that a medicine is used for."
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												{...register3('administration', { required: true })}
												label="Suggestion *"
												InputLabelProps={{ shrink: true }}
												fullWidth
												autoComplete="off"
											/>
										</Grid>
									</Grid>
								</Box>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleCancelAddSupply}>Cancel</Button>
							<LoadingButton loading={supplySubmitting} type="submit">
								Add
							</LoadingButton>
						</DialogActions>
					</Dialog>
				</form>
			</Box>
		</Layout>
	)
}

export default SummaryNote
