import { alpha } from '@mui/material/styles'
import { caseOnsiteMachine } from 'src/machines/caseOnsiteMachine.js'
import { useMachine } from '@xstate/react'
import { useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import BlockUi from 'react-block-ui'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FemaleIcon from '@mui/icons-material/Female'
import get from 'lodash/get'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Layout from 'src/components/layout'
import Link from '@mui/material/Link'
import LoadingButton from '@mui/lab/LoadingButton'
import MaleIcon from '@mui/icons-material/Male'
import MedicineCard from 'src/components/case/onsite/MedicineCard'
import Pagination from '@mui/material/Pagination'
import React, { Fragment } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import Skeleton from '@mui/material/Skeleton'
import SupplyCard from 'src/components/case/onsite/SupplyCard'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import PrescriptionItem from 'src/components/prescription/item.detail.card'

// TODO: Need to make as a utils?
const compoundGenerics = (data) => {
	const dataString = data.map((record) => `${get(record, 'name', '')} ${get(record, 'strength', '')}`)
	const compoundString = dataString.join(' ')
	return compoundString
}

function CaseOnsite() {
	let params = useParams()
	const [state, send] = useMachine(caseOnsiteMachine, {
		devTools: true,
		context: {
			id: params.id,
		},
	})

	const isRenderPatientLoading = state.matches('Loading Patient')
	const isRenderPatientLoadErrored = state.matches('Loading Patient errored')
	const isRenderPatientLoaded = [
		'Loaded Patient',
		'Summary Form',
		'Submiting Summary Form',
		'Form Load errored',
		'Prescription Form',
		'Submitting Prescription Form',
		'Show Adding Prescription Item',
		'Searching Medicine',
		'Searching Supply',
		'Show Add Medicine Modal',
		'Show Add Supply Modal',
		'Adding Medicine',
		'Adding Supply',
	].some((current) => state.matches(current))
	const isRenderForm = [
		'Summary Form',
		'Submiting Summary Form',
		'Prescription Form',
		'Submitting Prescription Form',
		'Show Adding Prescription Item',
		'Searching Medicine',
		'Searching Supply',
		'Show Add Medicine Modal',
		'Show Add Supply Modal',
		'Adding Medicine',
		'Adding Supply',
	].some((current) => state.matches(current))
	const isRenderSummaryForm = ['Loading Patient', 'Loaded Patient', 'Loading Patient errored', 'Summary Form', 'Submiting Summary Form'].some((current) =>
		state.matches(current)
	)
	const isRenderPrescriptionForm = [
		'Prescription Form',
		'Submitting Prescription Form',
		'Show Adding Prescription Item',
		'Searching Medicine',
		'Searching Supply',
		'Show Add Medicine Modal',
		'Show Add Supply Modal',
		'Adding Medicine',
		'Adding Supply',
	].some((current) => state.matches(current))
	const isRenderNextButton = ['Summary Form', 'Submiting Summary Form'].some((current) => state.matches(current))
	const isRenderSubmitButton = [
		'Prescription Form',
		'Submitting Prescription Form',
		'Show Adding Prescription Item',
		'Searching Medicine',
		'Searching Supply',
	].some((current) => state.matches(current))
	const isRenderShowAddItemModal = [
		'Show Adding Prescription Item',
		'Searching Medicine',
		'Searching Supply',
		'Show Add Medicine Modal',
		'Show Add Supply Modal',
		'Adding Medicine',
		'Adding Supply',
	].some((current) => state.matches(current))

	const isRenderSearching = ['Searching Medicine', 'Searching Supply'].some((current) => state.matches(current))

	const isRenderShowAddMedicineModal = ['Show Add Medicine Modal', 'Adding Medicine'].some((current) => state.matches(current))
	const isRenderShowAddSupplyModal = ['Show Add Supply Modal', 'Adding Supply'].some((current) => state.matches(current))
	// Medicine in form aaray
	const selectedMedicines = state.context.form.medicine
	const selectedSupplies = state.context.form.supply

	// pagination
	const totalMedicinePages = Math.ceil(state.context.item.medicine.total_results / state.context.item.medicine.limit)
	const currentMedicinePage = state.context.item.medicine.page
	const totalSupplyPages = Math.ceil(state.context.item.supply.total_results / state.context.item.supply.limit)
	const currentSupplyPage = state.context.item.supply.page

	// render validation isError for Boolena, error for message
	const isErrorSummaryNote = state.context.errors.summary_note !== ''
	const errorSummaryNote = state.context.errors.summary_note
	const isErrorPatientRecommendation = state.context.errors.patient_recommendation !== ''
	const errorPatientRecommendation = state.context.errors.patient_recommendation
	const isErrorMedicalRecommendation = state.context.errors.medical_recommendation !== ''
	const errorMedicalRecommendation = state.context.errors.medical_recommendation
	const isErrorDiagnosis = state.context.errors.diagnosis !== ''
	const errorDiagnosis = state.context.errors.diagnosis
	const isErrorAllergy = state.context.errors.allergy !== ''
	const errorAllergy = state.context.errors.allergy
	const isErrorCongenitalDisease = state.context.errors.congenital_disease !== ''
	const errorCongenitalDisease = state.context.errors.congenital_disease
	const isErrorMedicationUsed = state.context.errors.medication_used !== ''
	const errorMedicationUsed = state.context.errors.medication_used
	const isErrorChiefComplaint = state.context.errors.chief_complaint !== ''
	const errorChiefComplaint = state.context.errors.chief_complaint
	const isErrorPresentIllness = state.context.errors.present_illness !== ''
	const errorPresentIllness = state.context.errors.present_illness

	// render error on 2nd Layer Modal Medince
	const isError2ndMedAmount = state.context.errors.medicine.amount !== ''
	const error2ndMedAmount = state.context.errors.medicine.amount
	const isError2ndMedIndication = state.context.errors.medicine.indication !== ''
	const error2ndMedIndication = state.context.errors.medicine.indication
	const isError2ndMedAdministration = state.context.errors.medicine.administration !== ''
	const error2ndMedAdministration = state.context.errors.medicine.administration
	const isError2ndMedFrequency = state.context.errors.medicine.frequency !== ''
	const error2ndMedFrequency = state.context.errors.medicine.frequency
	const isError2ndMedRecommendation = state.context.errors.medicine.recommendation !== ''
	const error2ndMedRecommendation = state.context.errors.medicine.recommendation
	const isError2ndMedWarning = state.context.errors.medicine.warning !== ''
	const error2ndMedWarning = state.context.errors.medicine.warning

	// render error on 2nd Layer Model Supply
	const isError2ndSupAmount = state.context.errors.supply.amount !== ''
	const error2ndSupAmount = state.context.errors.supply.amount
	const isError2ndSupIndication = state.context.errors.supply.indication !== ''
	const error2ndSupIndication = state.context.errors.supply.indication

	const showAddItemModal = () => {
		send({ type: 'Add Item' })
	}

	const handleChangeItemTab = (event, value) => {
		send({ type: 'Change Tab', value: value })
	}

	const handleSubmitSearchMedicine = () => {
		send({ type: 'Submit Search Medicine', name: 'page', value: 1 })
	}

	const handleClearSearchMedicine = () => {
		send({ type: 'Clear Medicine Query' })
	}

	const handleChangeMedicinePage = (event, value) => {
		send({ type: 'Change Medicine Pagination', name: 'page', value: value })
	}

	const handleCloseMedicineModal = () => {
		send({ type: 'Close Prescription Modal' })
	}

	const handleSelectMedicine = (record) => () => {
		send({ type: 'Select Medicine', data: record })
	}

	const handleCloseMedicineSelectedModal = () => {
		send({ type: 'Close Add Medicine Modal' })
	}

	const handleAddMedicineSelectModal = () => {
		send({ type: 'Add Medicine' })
	}

	const handleSubmitSearchSupply = () => {
		send({ type: 'Submit Search Supply', name: 'page', value: 1 })
	}

	const handleSelectSupply = (record) => () => {
		send({ type: 'Select Supply', data: record })
	}

	const handleAddSupplySelectModal = () => {
		send({ type: 'Add Supply' })
	}

	const handleCloseSupplySelectedModal = () => {
		send({ type: 'Close Add Supply Modal' })
	}

	const handleClearSearchSupply = () => {
		send({ type: 'Clear Supply Query' })
	}

	const handleChangeSupplyPage = (event, value) => {
		send({ type: 'Change Supply Pagination', name: 'page', value: value })
	}

	return (
		<Layout>
			<Box p={4}>
				<Box mb={3}>
					<Breadcrumbs sx={{ mb: 2 }} aria-label="breadcrumb">
						<Link underline="hover" color="inherit" href="/onsite">
							Onsite
						</Link>
						<Typography color="text.primary">{params.id}</Typography>
					</Breadcrumbs>
					<Typography fontSize={24} gutterBottom>
						{isRenderSummaryForm && 'Summary'}
						{isRenderPrescriptionForm && 'Prescription'}
					</Typography>
					<Typography color="text.disabled" fontSize={16} gutterBottom>
						{isRenderSummaryForm && 'A brief case overview with medical record summary'}
						{isRenderPrescriptionForm && `สร้างใบสั่งยาสำหรับผู้ป่วย ${state.context.patient.first_name} ${state.context.patient.last_name}`}
					</Typography>
				</Box>
				<Box>
					<Box p={2} sx={{ border: 1, borderRadius: '16px', borderColor: 'text.disabled' }}>
						{isRenderPatientLoading && (
							<Box>
								<Skeleton animation="wave" />
								<Skeleton animation="wave" />
								<Skeleton animation="wave" />
							</Box>
						)}
						{isRenderPatientLoaded && (
							<Grid sx={{ flexGrow: 1 }} container spacing={0}>
								<Grid item xs={12} md={6}>
									<Typography variant="body1" gutterBottom>
										ชื่อ : {state.context.patient.first_name} {state.context.patient.last_name}
									</Typography>
									<Box sx={{ typography: 'caption', color: 'text.disabled', display: 'flex', alignItems: 'center', mb: 1 }} color="text.disabled">
										{state.context.patient.gender === 'male' ? (
											<Fragment>
												<MaleIcon /> Male {state.context.patient.age}y
											</Fragment>
										) : (
											<Fragment>
												<FemaleIcon /> Female {state.context.patient.age}y
											</Fragment>
										)}
									</Box>
								</Grid>
								<Grid item xs={12} md={6}>
									<Typography variant="body1" gutterBottom>
										อาการ : {state.context.patient.symptom}
									</Typography>
								</Grid>
							</Grid>
						)}
						{isRenderPatientLoadErrored && (
							<Box>
								{state.context.error_message}
								<button onClick={() => send({ type: 'Retry Load Patient' })}>click to reload</button>
							</Box>
						)}
					</Box>
					<Grid sx={{ flexGrow: 1, mt: 2 }} container spacing={0} direction="row" justifyContent="space-between" alignItems="center">
						{isRenderPatientLoading && (
							<React.Fragment>
								<Grid sx={{ px: 2, p: 1 }} item xs={12} sm={6} md={3}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid sx={{ px: 2, p: 1 }} item xs={12} sm={6} md={3}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid sx={{ px: 2, p: 1 }} item xs={12} sm={6} md={3}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid sx={{ px: 2, p: 1 }} item xs={12} sm={6} md={3}>
									<Skeleton animation="wave" />
								</Grid>
							</React.Fragment>
						)}
						{isRenderPatientLoaded && (
							<React.Fragment>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									item
									xs={12}
									sm={5}
									md={2}
									sx={{
										my: 1,
										p: 1,
										bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
										borderRadius: '8px',
									}}
								>
									<Grid>
										<Box sx={{ display: 'inline', typography: 'subtitle2', color: 'primary.main' }}>T</Box>
										<Box sx={{ ml: 1, display: 'inline', typography: 'subtitle2' }}>{state.context.patient.body_temperature}</Box>
									</Grid>
									<Grid>
										<Box sx={{ color: 'text.disabled', typography: 'subtitle2' }}>°C</Box>
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									item
									xs={12}
									sm={5}
									md={2}
									sx={{
										my: 1,
										p: 1,
										bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
										borderRadius: '8px',
									}}
								>
									<Grid>
										<Box sx={{ display: 'inline', typography: 'subtitle2', color: 'primary.main' }}>P</Box>
										<Box sx={{ ml: 1, display: 'inline', typography: 'subtitle2' }}>{state.context.patient.pulse}</Box>
									</Grid>
									<Grid>
										<Box sx={{ color: 'text.disabled', typography: 'subtitle2' }}>bpm</Box>
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									item
									xs={12}
									sm={5}
									md={2}
									sx={{
										my: 1,
										p: 1,
										bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
										borderRadius: '8px',
									}}
								>
									<Grid>
										<Box sx={{ display: 'inline', typography: 'subtitle2', color: 'primary.main' }}>BP</Box>
										<Box sx={{ ml: 1, display: 'inline', typography: 'subtitle2' }}>
											{state.context.patient.blood_pressure.sbp}/{state.context.patient.blood_pressure.dbp}
										</Box>
									</Grid>
									<Grid>
										<Box sx={{ color: 'text.disabled', typography: 'subtitle2' }}>mm/Hg</Box>
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									item
									xs={12}
									sm={5}
									md={2}
									sx={{
										my: 1,
										p: 1,
										bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
										borderRadius: '8px',
									}}
								>
									<Grid>
										<Box sx={{ display: 'inline', typography: 'subtitle2', color: 'primary.main' }}>R</Box>
										<Box sx={{ ml: 1, display: 'inline', typography: 'subtitle2' }}>{state.context.patient.respiratory_rate}</Box>
									</Grid>
									<Grid>
										<Box sx={{ color: 'text.disabled', typography: 'subtitle2' }}>br/m</Box>
									</Grid>
								</Grid>
							</React.Fragment>
						)}
					</Grid>
				</Box>
				{isRenderForm && (
					<form
						onSubmit={(e) => {
							e.preventDefault()
							if (state.matches('Summary Form')) {
								send({ type: 'Submit Summary Form' })
							} else {
								send({ type: 'Submit Presrciption Form' })
							}
						}}
					>
						{isRenderSummaryForm && (
							<Box my={2} py={2}>
								<Typography variant="h6" gutterBottom>
									Case Summary
								</Typography>
								<Grid sx={{ flexGrow: 1, mt: 1 }} container spacing={2}>
									<Grid item xs={12}>
										<TextField
											error={isErrorSummaryNote}
											helperText={errorSummaryNote}
											name="summary_note"
											label="Personal Note"
											variant="outlined"
											autoComplete="off"
											fullWidth
											multiline
											rows={2}
											value={state.context.form.summary_note}
											onChange={(event) => {
												send({ type: 'Form Input Change', name: event.target.name, value: event.target.value })
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											error={isErrorChiefComplaint}
											helperText={errorChiefComplaint}
											name="chief_complaint"
											label="Chief Complaint*"
											variant="outlined"
											autoComplete="off"
											multiline
											rows={2}
											fullWidth
											value={state.context.form.chief_complaint}
											onChange={(event) => {
												send({ type: 'Form Input Change', name: event.target.name, value: event.target.value })
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											error={isErrorPresentIllness}
											helperText={errorPresentIllness}
											name="present_illness"
											label="Present Illness"
											variant="outlined"
											autoComplete="off"
											multiline
											rows={2}
											fullWidth
											value={state.context.form.present_illness}
											onChange={(event) => {
												send({ type: 'Form Input Change', name: event.target.name, value: event.target.value })
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											error={isErrorPatientRecommendation}
											helperText={errorPatientRecommendation}
											name="patient_recommendation"
											label="Patient Recommendation*"
											variant="outlined"
											autoComplete="off"
											fullWidth
											value={state.context.form.patient_recommendation}
											onChange={(event) => {
												send({ type: 'Form Input Change', name: event.target.name, value: event.target.value })
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											error={isErrorMedicalRecommendation}
											helperText={errorMedicalRecommendation}
											name="medical_recommendation"
											label="Medical Recommendation"
											variant="outlined"
											autoComplete="off"
											fullWidth
											value={state.context.form.medical_recommendation}
											onChange={(event) => {
												send({ type: 'Form Input Change', name: event.target.name, value: event.target.value })
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											error={isErrorDiagnosis}
											helperText={errorDiagnosis}
											name="diagnosis"
											label="Diagnosis*"
											variant="outlined"
											autoComplete="off"
											fullWidth
											value={state.context.form.diagnosis}
											onChange={(event) => {
												send({ type: 'Form Input Change', name: event.target.name, value: event.target.value })
											}}
										/>
									</Grid>
								</Grid>
								<Divider sx={{ mt: 3, mb: 2 }} />
								<Typography variant="h6" gutterBottom>
									Patient Health Profile
								</Typography>
								<Grid sx={{ flexGrow: 1, mt: 1, mb: 2 }} container spacing={2}>
									<Grid item xs={12}>
										<TextField
											error={isErrorCongenitalDisease}
											helperText={errorCongenitalDisease}
											name="congenital_disease"
											label="Congenital Disease"
											variant="outlined"
											autoComplete="off"
											fullWidth
											value={state.context.form.congenital_disease}
											onChange={(event) => {
												send({ type: 'Form Input Change', name: event.target.name, value: event.target.value })
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											error={isErrorAllergy}
											helperText={errorAllergy}
											name="allergy"
											label="Allergy"
											variant="outlined"
											autoComplete="off"
											fullWidth
											value={state.context.form.allergy}
											onChange={(event) => {
												send({ type: 'Form Input Change', name: event.target.name, value: event.target.value })
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											error={isErrorMedicationUsed}
											helperText={errorMedicationUsed}
											name="medication_used"
											label="Medical Used"
											variant="outlined"
											autoComplete="off"
											fullWidth
											value={state.context.form.medication_used}
											onChange={(event) => {
												send({ type: 'Form Input Change', name: event.target.name, value: event.target.value })
											}}
										/>
									</Grid>
								</Grid>
								<Typography variant="h6" gutterBottom>
									Other
								</Typography>
								<Grid sx={{ flexGrow: 1 }} container spacing={2}>
									<Grid item xs={12} sm={6} md={3}>
										<Checkbox
											name="smoking"
											checked={state.context.form.smoking}
											onChange={(event) => {
												send({ type: 'Form Input Change', name: event.target.name, value: event.target.checked })
											}}
											inputProps={{ 'aria-label': 'controlled' }}
										/>{' '}
										Smoke
									</Grid>
									<Grid item xs={12} sm={6} md={3}>
										<Checkbox
											name="alcohol"
											checked={state.context.form.alcohol}
											onChange={(event) => {
												send({ type: 'Form Input Change', name: event.target.name, value: event.target.checked })
											}}
											inputProps={{ 'aria-label': 'controlled' }}
										/>{' '}
										Alcohol
									</Grid>
									<Grid item xs={12} sm={6} md={3}>
										<Checkbox
											name="pregnant"
											checked={state.context.form.pregnant}
											onChange={(event) => {
												send({ type: 'Form Input Change', name: event.target.name, value: event.target.checked })
											}}
											inputProps={{ 'aria-label': 'controlled' }}
										/>{' '}
										Pregnent
									</Grid>
									<Grid item xs={12} sm={6} md={3}>
										<Checkbox
											name="breast_feeding"
											checked={state.context.form.breast_feeding}
											onChange={(event) => {
												send({ type: 'Form Input Change', name: event.target.name, value: event.target.checked })
											}}
											inputProps={{ 'aria-label': 'controlled' }}
										/>{' '}
										Breast feeding
									</Grid>
								</Grid>
							</Box>
						)}
						<Dialog maxWidth="lg" fullWidth={true} open={isRenderShowAddMedicineModal} onClose={() => {}}>
							<DialogTitle>
								Medicine Detail
								<IconButton
									aria-label="close"
									onClick={handleCloseMedicineSelectedModal}
									sx={{
										position: 'absolute',
										right: 8,
										top: 8,
										color: (theme) => theme.palette.grey[500],
									}}
								>
									<CloseIcon />
								</IconButton>
							</DialogTitle>
							<DialogContent>
								<Box sx={{ flexGrow: 1 }}>
									<Typography variant="body2" color="text.disabled" gutterBottom>
										{get(state.context.item.medicine.select, 'local_name', '')} 1 {get(state.context.item.medicine.select, 'presentation_name', '')} x{' '}
										{get(state.context.item.medicine.select, 'quantity', '')} {get(state.context.item.medicine.select, 'presentation_unit', '')}
									</Typography>
									<Grid container spacing={2} justifyContent="center">
										<Grid item xs={12}>
											<TextField
												error={isError2ndMedAmount}
												helperText={error2ndMedAmount}
												name="amount"
												label="Amount"
												variant="outlined"
												autoComplete="off"
												fullWidth
												inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
												InputProps={{
													endAdornment: <InputAdornment position="end">{state.context.item.medicine.select.presentation_name}</InputAdornment>,
												}}
												value={state.context.item.medicine.select.amount}
												onChange={(event) => {
													send({ type: 'Change Form Input', name: event.target.name, value: event.target.value })
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<Typography variant="body2" color="text.disabled" gutterBottom>
												How to use this Medicine.
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<TextField
												error={isError2ndMedIndication}
												helperText={error2ndMedIndication}
												name="indication"
												label="Indication"
												variant="outlined"
												autoComplete="off"
												fullWidth
												value={state.context.item.medicine.select.indication}
												onChange={(event) => {
													send({ type: 'Change Form Input', name: event.target.name, value: event.target.value })
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												error={isError2ndMedAdministration}
												helperText={error2ndMedAdministration}
												name="administration"
												label="Administration"
												variant="outlined"
												autoComplete="off"
												fullWidth
												value={state.context.item.medicine.select.administration}
												onChange={(event) => {
													send({ type: 'Change Form Input', name: event.target.name, value: event.target.value })
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												error={isError2ndMedFrequency}
												helperText={error2ndMedFrequency}
												name="frequency"
												label="Frequency"
												variant="outlined"
												autoComplete="off"
												fullWidth
												value={state.context.item.medicine.select.frequency}
												onChange={(event) => {
													send({ type: 'Change Form Input', name: event.target.name, value: event.target.value })
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												error={isError2ndMedRecommendation}
												helperText={error2ndMedRecommendation}
												name="recommendation"
												label="Recomendation"
												variant="outlined"
												autoComplete="off"
												fullWidth
												value={state.context.item.medicine.select.recommendation}
												onChange={(event) => {
													send({ type: 'Change Form Input', name: event.target.name, value: event.target.value })
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												error={isError2ndMedWarning}
												helperText={error2ndMedWarning}
												name="warning"
												label="Warning"
												variant="outlined"
												autoComplete="off"
												fullWidth
												value={state.context.item.medicine.select.warning}
												onChange={(event) => {
													send({ type: 'Change Form Input', name: event.target.name, value: event.target.value })
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<Button variant="contained" fullWidth onClick={handleAddMedicineSelectModal}>
												Add
											</Button>
										</Grid>
									</Grid>
								</Box>
							</DialogContent>
						</Dialog>
						<Dialog maxWidth="lg" fullWidth={true} open={isRenderShowAddSupplyModal} onClose={() => {}}>
							<DialogTitle>
								Supply Detail
								<IconButton
									aria-label="close"
									onClick={handleCloseSupplySelectedModal}
									sx={{
										position: 'absolute',
										right: 8,
										top: 8,
										color: (theme) => theme.palette.grey[500],
									}}
								>
									<CloseIcon />
								</IconButton>
							</DialogTitle>
							<DialogContent>
								<Box sx={{ flexGrow: 1 }}>
									<Typography variant="body2" color="text.disabled" gutterBottom>
										{get(state.context.item.supply.select, 'local_name', '')}
									</Typography>
									<Grid container spacing={2} justifyContent="center">
										<Grid item xs={12}>
											<TextField
												error={isError2ndSupAmount}
												helperText={error2ndSupAmount}
												name="amount"
												label="Amount"
												variant="outlined"
												autoComplete="off"
												fullWidth
												inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
												InputProps={{
													endAdornment: <InputAdornment position="end">Unit</InputAdornment>,
												}}
												value={state.context.item.supply.select.amount}
												onChange={(event) => {
													send({ type: 'Change Form Input', name: event.target.name, value: event.target.value })
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<Typography variant="body2" color="text.disabled" gutterBottom>
												How to use this Product.
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<TextField
												error={isError2ndSupIndication}
												helperText={error2ndSupIndication}
												name="indication"
												label="Indication"
												variant="outlined"
												autoComplete="off"
												fullWidth
												value={state.context.item.supply.select.indication}
												onChange={(event) => {
													send({ type: 'Change Form Input', name: event.target.name, value: event.target.value })
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<Button variant="contained" fullWidth onClick={handleAddSupplySelectModal}>
												Add
											</Button>
										</Grid>
									</Grid>
								</Box>
							</DialogContent>
						</Dialog>
						<Dialog maxWidth="lg" fullWidth={true} open={isRenderShowAddItemModal} onClose={() => {}}>
							<DialogTitle>
								Prescription
								<IconButton
									aria-label="close"
									onClick={handleCloseMedicineModal}
									sx={{
										position: 'absolute',
										right: 8,
										top: 8,
										color: (theme) => theme.palette.grey[500],
									}}
								>
									<CloseIcon />
								</IconButton>
							</DialogTitle>
							<DialogContent>
								<Box sx={{ flexGrow: 1 }}>
									<BlockUi tag="div" blocking={isRenderSearching}>
										<Grid container spacing={2} justifyContent="center">
											<Grid item xs={12}>
												<Tabs value={state.context.item.mode} onChange={handleChangeItemTab} aria-label="item type">
													<Tab label="Medicine" value="Medicine" />
													<Tab label="Supply" value="Supply" />
												</Tabs>
											</Grid>
											<Grid
												item
												xs={12}
												role="tabpanel"
												hidden={state.context.item.mode !== 'Medicine'}
												id={`item-medicine-tabpanel`}
												aria-labelledby={`item-medicine-tab`}
											>
												<Grid container spacing={2} justifyContent={{ xs: 'center', md: 'space-between' }}>
													<Grid item xs={12}>
														<TextField
															name="local_name"
															label="Trade Name"
															variant="outlined"
															autoComplete="off"
															fullWidth
															helperText="Ex. Tylenol"
															value={state.context.item.medicine.local_name}
															onChange={(event) => {
																send({ type: 'Change Medicine Query', name: event.target.name, value: event.target.value })
															}}
															InputProps={{
																startAdornment: (
																	<InputAdornment position="start">
																		<SearchIcon />
																	</InputAdornment>
																),
															}}
														/>
													</Grid>
													<Grid item xs={12}>
														<TextField
															name="generics_name"
															label="Generic Name"
															variant="outlined"
															autoComplete="off"
															fullWidth
															helperText="Ex. paracetamol"
															value={state.context.item.medicine.generics_name}
															onChange={(event) => {
																send({ type: 'Change Medicine Query', name: event.target.name, value: event.target.value })
															}}
															InputProps={{
																startAdornment: (
																	<InputAdornment position="start">
																		<SearchIcon />
																	</InputAdornment>
																),
															}}
														/>
													</Grid>
													<Grid item xs={12} md={2}>
														<Button variant="text" fullWidth onClick={handleClearSearchMedicine}>
															Clear
														</Button>
													</Grid>
													<Grid item xs={12} md={2}>
														<Button variant="contained" fullWidth onClick={handleSubmitSearchMedicine}>
															Search
														</Button>
													</Grid>
												</Grid>
												<Grid item xs={12} mt={2}>
													<Divider />
												</Grid>
												<Grid container justifyContent="space-between" item xs={12} spacing={2}>
													<Grid item xs={12} mt={2}>
														<Typography variant="body1" color="text.disabled" gutterBottom>
															About {state.context.item.medicine.total_results} results
														</Typography>
													</Grid>
													{state.context.item.medicine.data.map((record) => {
														const _id = record._id
														const local_name = get(record, 'local_name', '')
														const generics = get(record, 'generics', [])
														const presentation_name = get(record, 'presentation_name', '')
														const presentation_unit = get(record, 'presentation_unit', '')
														const quantity = get(record, 'quantity', 0)
														const hasInCart = selectedMedicines.filter((record) => record._id === _id).length === 1
														return (
															<MedicineCard
																key={_id}
																local_name={local_name}
																generics={generics}
																presentation_name={presentation_name}
																presentation_unit={presentation_unit}
																quantity={quantity}
																onSelect={handleSelectMedicine(record)}
																disabled={hasInCart}
															/>
														)
													})}
												</Grid>
												<Grid container item justifyContent="flex-end" xs={12} mt={2}>
													<Pagination count={totalMedicinePages} page={currentMedicinePage} onChange={handleChangeMedicinePage} color="primary" />
												</Grid>
											</Grid>
											<Grid
												item
												xs={12}
												role="tabpanel"
												hidden={state.context.item.mode !== 'Supply'}
												id={`item-medicine-tabpanel`}
												aria-labelledby={`item-medicine-tab`}
											>
												<Grid container spacing={2} justifyContent={{ xs: 'center', md: 'space-between' }}>
													<Grid item xs={12}>
														<TextField
															name="local_name"
															label="Trade Name"
															variant="outlined"
															autoComplete="off"
															fullWidth
															helperText="Ex. ไซริงค์"
															value={state.context.item.supply.local_name}
															onChange={(event) => {
																send({ type: 'Change Supply Query', name: event.target.name, value: event.target.value })
															}}
															InputProps={{
																startAdornment: (
																	<InputAdornment position="start">
																		<SearchIcon />
																	</InputAdornment>
																),
															}}
														/>
													</Grid>
													<Grid item xs={12} md={2}>
														<Button variant="text" fullWidth onClick={handleClearSearchSupply}>
															Clear
														</Button>
													</Grid>
													<Grid item xs={12} md={2}>
														<Button variant="contained" fullWidth onClick={handleSubmitSearchSupply}>
															Search
														</Button>
													</Grid>
												</Grid>
												<Grid item xs={12} mt={2}>
													<Divider />
												</Grid>
												<Grid container justifyContent="space-between" item xs={12} spacing={2}>
													<Grid item xs={12} mt={2}>
														<Typography variant="body1" color="text.disabled" gutterBottom>
															About {state.context.item.supply.total_results} results
														</Typography>
													</Grid>
													{state.context.item.supply.data.map((record) => {
														const _id = record._id
														const local_name = record.local_name
														const description = record.description
														const hasInCart = selectedSupplies.filter((record) => record._id === _id).length === 1
														return (
															<SupplyCard key={_id} name={local_name} description={description} onSelect={handleSelectSupply(record)} disabled={hasInCart} />
														)
													})}
												</Grid>
												<Grid container item justifyContent="flex-end" xs={12} mt={2}>
													<Pagination count={totalSupplyPages} page={currentSupplyPage} onChange={handleChangeSupplyPage} color="primary" />
												</Grid>
											</Grid>
										</Grid>
									</BlockUi>
								</Box>
							</DialogContent>
						</Dialog>
						{isRenderPrescriptionForm && (
							<Box my={2} py={2}>
								<Grid container direction="row" justifyContent="flex-end" alignItems="center">
									<Grid item xs={12} md={2}>
										<Button variant="contained" size="small" fullWidth startIcon={<AddIcon />} onClick={showAddItemModal}>
											add item
										</Button>
									</Grid>
								</Grid>
								<Box my={2} p={2} sx={{ border: 1, borderRadius: '16px', borderColor: 'text.disabled' }}>
									<Typography variant="body1" gutterBottom>
										Medicine {selectedMedicines.length}
									</Typography>
									{selectedMedicines.map((record) => {
										const _id = record._id
										const local_name = get(record, 'local_name', '')
										const generics = compoundGenerics(get(record, 'generics', []))
										const presentation_name = get(record, 'presentation_name', '')
										const presentation_unit = get(record, 'presentation_unit', '')
										const quantity = get(record, 'quantity', 0)
										const administration = get(record, 'administration', '')
										const frequency = get(record, 'frequency', '')
										const indication = get(record, 'indication', '')
										const recommendation = get(record, 'recommendation', '')
										const warning = get(record, 'warning', '')
										const pkg_detail = `1 ${presentation_name} x ${quantity} ${presentation_unit}`
										const description = `${indication} ${administration} ${frequency} ${recommendation} ${warning}`
										return (
											<PrescriptionItem
												key={_id}
												name={local_name}
												line1={generics}
												line2={description}
												package_detail={pkg_detail}
												onClick={() => send({ type: 'Remove Medicine Item', id: _id })}
											/>
										)
									})}
								</Box>
								<Box my={2} p={2} sx={{ border: 1, borderRadius: '16px', borderColor: 'text.disabled' }}>
									<Typography variant="body1" gutterBottom>
										Supply {selectedSupplies.length}
									</Typography>
									{selectedSupplies.map((record) => {
										const _id = record._id
										const local_name = get(record, 'local_name', '')
										const description = get(record, 'description', '')
										const indication = get(record, 'indication')
										return (
											<PrescriptionItem
												key={_id}
												name={local_name}
												line1={description}
												line2={indication}
												package_detail={''}
												onClick={() => send({ type: 'Remove Supply Item', id: _id })}
											/>
										)
									})}
								</Box>
							</Box>
						)}
						<Box>
							{isRenderNextButton && (
								<Fragment>
									<Box sx={{ mx: 'auto', minWidth: 100, maxWidth: 140 }}>
										<LoadingButton loading={state.matches('Submiting Summary Form')} type="submit" variant="contained" fullWidth>
											Next
										</LoadingButton>
									</Box>
									<Box>{state.context.error_message}</Box>
								</Fragment>
							)}
							{isRenderSubmitButton && (
								<Box sx={{ mx: 'auto', minWidth: 100, maxWidth: 140 }}>
									<LoadingButton loading={state.matches('Submitting Prescription Form')} type="submit" variant="contained" fullWidth>
										Submit
									</LoadingButton>
								</Box>
							)}
						</Box>
					</form>
				)}
			</Box>
		</Layout>
	)
}

export default CaseOnsite
