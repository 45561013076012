import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Link from '@mui/material/Link'
import LoadingButton from '@mui/lab/LoadingButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import React from 'react'
import Typography from '@mui/material/Typography'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import VpnKeyIcon from '@mui/icons-material/VpnKey'

export default function Login({ register, readyToLogin, loading, errors }) {
	const [values, setValues] = React.useState({
		showPassword: false,
	})

	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword,
		})
	}

	const handleMouseDownPassword = (event) => {
		event.preventDefault()
	}

	const error_phone = errors.login_phone_number

	return (
		<div>
			<Typography fontSize={20} mb={2}>
				Sign In for Admin
			</Typography>
			{error_phone && (
				<Alert severity="error" sx={{ mb: 2 }}>
					{error_phone.message}
				</Alert>
			)}
			<OutlinedInput
				fullWidth
				{...register('login_phone_number', {
					required: 'Phone number is required.',
					pattern: { value: /^[0-9]{10}$/, message: 'Invalid format.' },
				})}
				placeholder="Phone Number"
				autoComplete="username"
				startAdornment={
					<InputAdornment position="start">
						<AccountCircleIcon />
					</InputAdornment>
				}
				sx={{ mb: 1.5 }}
			/>
			{!readyToLogin && (
				<LoadingButton loading={loading} color="primary" variant="contained" sx={{ mt: 2 }} type="submit" fullWidth>
					Next
				</LoadingButton>
			)}
			{readyToLogin && (
				<>
					<OutlinedInput
						fullWidth
						placeholder="Password"
						type={values.showPassword ? 'text' : 'password'}
						autoComplete="current-password"
						{...register('password')}
						startAdornment={
							<InputAdornment position="start">
								<VpnKeyIcon />
							</InputAdornment>
						}
						endAdornment={
							<InputAdornment position="end">
								<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
									{values.showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						sx={{ mb: 4 }}
					/>
					<LoadingButton loading={loading} color="primary" variant="contained" type="submit" fullWidth>
						Sign In
					</LoadingButton>
				</>
			)}
			<Link href="/reset-password">
				<Typography fontSize={14} mt={3}>
					forgot your password
				</Typography>
			</Link>
		</div>
	)
}
