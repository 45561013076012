import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import React from 'react'
import Typography from '@mui/material/Typography'

function compoundIndication(data) {
	const { indication = '', administration = '', administration_amount = '', administration_unit = '', frequency = '', recommendation = '' } = data
	return `${indication} ${administration} ${administration_amount} ${administration_unit} ${frequency} ${recommendation}`
}

function compoundPresentation(data) {
	const { presentation_quantity = '' } = data
	const { presentation_name = '' } = data
	const { presentation_unit_quantity = '' } = data
	const { presentation_unit = '' } = data
	let _resp = `${presentation_quantity} ${presentation_name}`

	if (presentation_unit_quantity) {
		_resp = `${_resp} - ${presentation_unit_quantity}`
	}

	if (presentation_unit) {
		_resp = `${_resp} ${presentation_unit}`
	}

	return _resp
}

function MedicineCard(props) {
	// function from props
	const { onRemoveClick, data } = props
	// variable from props
	const { trade_name, full_generics } = data
	const suggestion = compoundIndication(data)
	const presentation = compoundPresentation(data)

	return (
		<Paper variant="outlined" sx={{ p: 2 }}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
				}}
			>
				<IconButton aria-label="close" onClick={onRemoveClick}>
					<CloseIcon />
				</IconButton>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					px: 2,
				}}
			>
				<Box>
					<Typography variant="body2" gutterBottom>
						{trade_name}
					</Typography>
					<Typography variant="body2" gutterBottom>
						{presentation}
					</Typography>
					<Typography variant="body2" gutterBottom sx={{ color: 'text.disabled' }}>
						{full_generics}
					</Typography>
				</Box>
				<Box>
					<Typography variant="body2" sx={{ color: 'text.disabled' }}>
						{suggestion}
					</Typography>
				</Box>
			</Box>
		</Paper>
	)
}

MedicineCard.defaultProps = {
	onRemoveClick: () => {},
	data: {
		trade_name: '',
		indication: '',
		administration: '',
		administration_amount: '',
		administration_unit: '',
		frequency: '',
		recommendation: '',
		full_generics: '',
		presentation_quantity: '',
		presentation_name: '',
		presentation_unit_quantity: '',
		presentation_unit: '',
	},
}

export default React.memo(MedicineCard)
