import Axios from 'src/Axios'
import React, { createContext, useContext, useMemo, useCallback, useState } from 'react'
import { useSnackbar } from 'notistack'

const ScheduleContext = createContext()
ScheduleContext.displayName = 'ScheduleContext'

function ScheduleProvider(props) {
	const { enqueueSnackbar } = useSnackbar()
	const [schedule, setSchedule] = useState([])
	const [fetching, setFetching] = useState(false)

	const getSchedule = useCallback(
		async (current_date) => {
			setFetching(true)
			try {
				const response = await Axios({
					method: 'get',
					url: `api/tele-health-slot`,
					params: { current_date },
				})

				setSchedule(response.data?.schedule)
			} catch (error) {
				throw new Error()
			}
			setFetching(false)
		},
		[setSchedule, setFetching]
	)

	const deleteSchedule = useCallback(async (id) => {
		try {
			await Axios({
				method: 'delete',
				url: `api/tele-health-slot/` + id,
			})
		} catch (error) {
			throw new Error()
		}
	}, [])

	const createScheduleMultiple = useCallback(async (data) => {
		try {
			// WARNING: no-unused-vars
			// eslint-disable-next-line
			const response = await Axios({
				method: 'post',
				url: `api/tele-health-slot`,
				data,
			})
		} catch (error) {
			const errorMessageList = error.response.data?.errors || []
			enqueueSnackbar(
				<pre>
					{errorMessageList.map((errorMessage, key) => (
						<div key={key}>
							{errorMessage.msg}: {errorMessage.value}
							<br />
						</div>
					))}
				</pre>
			)
			throw new Error()
		}
		// WARNING: react-hooks/exhaustive-deps
		// eslint-disable-next-line
	}, [])

	const value = useMemo(
		() => ({
			schedule,
			setSchedule,
			getSchedule,
			createScheduleMultiple,
			deleteSchedule,
			fetching,
		}),
		[getSchedule, fetching, schedule, setSchedule, createScheduleMultiple, deleteSchedule]
	)

	return <ScheduleContext.Provider value={value} {...props} />
}

function useSchedule() {
	const context = useContext(ScheduleContext)
	return context
}

export { ScheduleProvider, useSchedule }
