import { useAuth } from 'src/context/auth-context.js'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import Avatar from '@mui/material/Avatar'
import axios from 'src/Axios'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import Layout from 'src/components/layout'
import LoadingButton from '@mui/lab/LoadingButton'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import React, { useState } from 'react'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

async function getBase64(file) {
	// console.log('🚀 ~ file: profile.js ~ line 17 ~ getBase64 ~ file', file)

	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		// Convert the file to base64 text
		reader.addEventListener('load', () => resolve(reader.result))
		reader.onerror = () => {
			return reject('error is occured while reading file as base64')
		}
		reader.readAsDataURL(file)
	})
}

function Profile() {
	const { user, refetchProfile } = useAuth()
	const { enqueueSnackbar } = useSnackbar()
	const {
		register,
		handleSubmit,
		// setError,
		watch,
		// formState: { errors },
	} = useForm({
		defaultValues: {
			pre_name: user?.pre_name?.th || '',
			first_name: user?.first_name || '',
			last_name: user?.last_name || '',
			login_phone_number: user?.login_phone_number || '',
			cid: user?.cid || '',
			birth_date: user?.birth_date || null,
			doctor: {
				register_no: user?.doctor?.register_no || '',
				speciality: user?.doctor?.speciality?.th || '',
				description: user?.doctor?.description || '',
				image: user?.doctor?.image_url || undefined,
				base_fee: user?.doctor?.base_fee || 0,
			},
		},
	})

	const { onChange: onChangeImage } = register('doctor.image')
	const [loading, setLoading] = useState(false)

	const submit = async (data) => {
		setLoading(true)
		try {
			await axios({
				method: 'put',
				url: `api/user/profile`,
				data: data,
			})
			// reload user context
			await refetchProfile()
			window.alert('Update profile successfully !')
		} catch (error) {
			throw error
		} finally {
			setLoading(false)
		}
	}

	return (
		<Layout>
			<Box p={1}>
				<Box mb={3}>
					<Grid container spacing={2} direction="row">
						<Grid item>
							<Box mt={4} mx={2}>
								<Typography variant="h5" gutterBottom component="div">
									Profile
								</Typography>
								<Typography variant="body2" gutterBottom component="div">
									จัดการข้อมูลโปรไฟล์ของคุณ ผู้ป่วยสามารถมองเห็นข้อมูลบางส่วนเพื่อประกอบการตัดสินใจในการปรึกษา ยกเว้นข้อมูลสำคัญของคุณถูกจัดเก็บไว้ส่วนตัว
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Box mx={2}>
					<form onSubmit={handleSubmit(submit)}>
						<Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
							<Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
								<Box mb={2}>
									<Typography variant="body2" component="span" gutterBottom>
										Information
									</Typography>
								</Box>
								<Stack spacing={2}>
									<TextField label="Prename" disabled required fullWidth {...register('pre_name')} />
									<TextField label="First name" disabled required fullWidth {...register('first_name')} />
									<TextField label="Last name" disabled required fullWidth {...register('last_name')} />
									<TextField
										label="Phone number"
										disabled
										required
										fullWidth
										{...register('login_phone_number', {
											pattern: {
												value: /^[0-9]{10}$/,
												message: 'Invalid Phone Number format.',
											},
										})}
									/>
									<TextField
										label="CID"
										disabled
										required
										fullWidth
										{...register('cid', {
											minLength: {
												value: 13,
												message: 'Please fill 13 digits cid',
											},
											maxLength: {
												value: 13,
												message: 'Please fill 13 digits cid',
											},
										})}
									/>
									<MobileDatePicker
										label="Date of birth *"
										inputFormat="DD/MM/yyyy"
										disabled
										value={watch('birth_date') || null}
										onChange={() => {}}
										renderInput={(params) => <TextField fullWidth {...params} />}
									/>
									<TextField
										label="Register Number"
										required
										disabled
										fullWidth
										{...register('doctor.register_no', {
											minLength: {
												value: 5,
												message: 'Please fill 5 digits register number',
											},
											maxLength: {
												value: 5,
												message: 'Please fill 5 digits register number',
											},
										})}
									/>
									<TextField label="Speciality" disabled fullWidth {...register('doctor.speciality')} />
									<TextField label="Price of your services" fullWidth {...register('doctor.base_fee')} />
									<TextField label="Description" multiline rows={4} fullWidth {...register('doctor.description')} />
									<Typography variant="caption" display="block" gutterBottom>
										Description จะแสดงในข้อมูลโปรไฟล์ของคุณที่ผู้ป่วยสามารถเห็นได้
									</Typography>
								</Stack>
								<Box my={2}>
									<LoadingButton loading={loading} loadingIndicator="Saving..." type="submit" variant="outlined">
										Apply
									</LoadingButton>
								</Box>
							</Grid>
							<Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
								<Box mb={2}>
									<Typography variant="body2" component="span" gutterBottom>
										Profile Image
									</Typography>
								</Box>
								<Box px={0}>
									<Grid container columnSpacing={2} alignItems="center">
										<Grid item>
											<Avatar
												sx={{
													width: {
														xs: 70,
														sm: 100,
														md: 120,
														lg: 140,
														xl: 150,
													},
													height: {
														xs: 70,
														sm: 100,
														md: 120,
														lg: 140,
														xl: 150,
													},
												}}
												variant="square"
												src={watch('doctor')?.image}
											/>
										</Grid>
										<Grid item>
											<Typography variant="body2" color="text.secondary" component="span">
												.JPG file with max 5MB.
											</Typography>
											<Box mt={1}>
												<label htmlFor="contained-button-file">
													<Input
														accept="image/*"
														id="contained-button-file"
														type="file"
														sx={{ display: 'none' }}
														onChange={async (event) => {
															try {
																const validImageTypes = ['image/jpeg', 'image/jpg']
																const file = event.target.files[0]
																// console.log(file)
																if (!validImageTypes.includes(file.type)) {
																	enqueueSnackbar('File type allow only JPG/JPEG', { variant: 'error' })
																} else if (file.size / (1024 * 1024) > 5) {
																	enqueueSnackbar('File size is limited to 5MB', { variant: 'error' })
																	// showError("The file size is more than 1MB, please use another image.")
																} else {
																	const img = await getBase64(file)
																	onChangeImage({
																		target: {
																			name: 'doctor.image',
																			value: img,
																		},
																	})
																}
															} catch {}
														}}
													/>
													<Button variant="contained" component="span" size="small">
														Upload an image
													</Button>
												</label>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</form>
				</Box>
			</Box>
		</Layout>
	)
}

export default Profile
