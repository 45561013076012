import '@opentok/client'
import { history } from 'src/utils/history'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@mui/material/styles'
import AppProviders from 'src/context'
import CssBaseline from '@mui/material/CssBaseline'
import CustomRouter from 'src/utils/custom-router'
import DateAdapter from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import React from 'react'
import ReactDOM from 'react-dom'
import theme from 'src/Theme.js'

import 'react-block-ui/style.css'

import App from './App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<SnackbarProvider maxSnack={3}>
				<CustomRouter history={history}>
					<AppProviders>
						<LocalizationProvider dateAdapter={DateAdapter}>
							<App />
						</LocalizationProvider>
					</AppProviders>
				</CustomRouter>
			</SnackbarProvider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
