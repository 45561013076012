import { AuthProvider } from 'src/context/auth-context.js'
import { ScheduleProvider } from 'src/context/schedule-context.js'

function AppProviders({ children }) {
	return (
		<AuthProvider>
			<ScheduleProvider>{children}</ScheduleProvider>
		</AuthProvider>
	)
}

export default AppProviders
