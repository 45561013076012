import Box from '@mui/material/Box'
import React, { useState, useEffect } from 'react'
import Layout from 'src/components/layout'
import { Manager } from 'socket.io-client'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'src/context/auth-context.js'
import moment from 'moment'

import PatientDetail from 'src/components/case/PatientDetail'

const ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT
// const socket = socketIOClient(SOCKET_API_ENDPOINT)
// const doctor_id = "617b7442bf118c97f12a3f0c"

function Case() {
	let navigate = useNavigate()
	const { user } = useAuth()
	const doctor_id = user?._id
	// eslint-disable-next-line
	const [_socket, setSocket] = useState()
	const [appointments, setAppointments] = useState([])
	//!add summary session
	const [currentTime, setCurrentTime] = useState(moment())

	useEffect(() => {
		// const socket = socketIOClient(ENDPOINT);
		const manager = new Manager(ENDPOINT, {
			reconnectionDelayMax: 10000,
		})
		const socket = manager.socket('/doctor', {
			auth: {
				doctor_key: process.env.REACT_APP_DOCTOR_KEY,
			},
		})
		// console.log(socket)
		if (socket) {
			socket.on('connect', () => {
				// console.log('connect', socket.connected)
				// console.log(socket.id)
				socket.emit('join', doctor_id)
				setSocket(socket)
			})
			socket.on('message', (data) => {
				// console.log('message', data)
			})
			socket.on('appointments:list', ({ appointments, sid }) => {
				if (sid === socket.id) {
					// console.log('🚀 ~ file: App.js ~ line 32 ~ socket.on ~ appointments', appointments)
					setAppointments(appointments)
					// console.log('🚀 ~ file: App.js ~ line 27 ~ socket.on ~ sid', sid)
					// setNote(notes);
				}
			})
			socket.on('appointments:new', ({ new_appointment }) => {
				setAppointments([new_appointment, ...appointments])
				// console.log('appointments:new', new_appointment)
			})

			socket.on('disconnect', () => {
				// console.log('disconnect', socket.connected)
				// console.log(socket.id) // undefined
			})
		}

		//! set time interval
		const interval = setInterval(() => setCurrentTime(moment()), 1000)

		// CLEAN UP THE EFFECT
		return () => {
			socket.disconnect()
			clearInterval(interval)
		}
		// WARNING: react-hooks/exhaustive-deps
		// eslint-disable-next-line
	}, [])

	return (
		<Layout>
			<Box p={4}>
				<Box mb={5}>
					<Box fontSize={14}>Case</Box>
					<Box fontSize={24}>Your Appointment</Box>
				</Box>
				{appointments.filter((e) => e.status === 'wait_confirm').length > 0 ? (
					<Box>
						<Box mb={3}>
							<Box>Waiting for summary ({appointments.filter((e) => e.status === 'wait_confirm').length})</Box>
						</Box>
						{appointments
							.filter((e) => e.status === 'wait_confirm')
							.map((row) => {
								const onClick = () => navigate('/case/' + row._id)
								return (
									<Box key={row._id} mb={1} px={3} py={2} borderRadius="16px" border="1px solid #D8D8D8" display="flex">
										<PatientDetail {...row} onClick={onClick} buttonTitle="Detail" />
									</Box>
								)
							})}
					</Box>
				) : null}
				<Box mb={3} mt={5}>
					<Box>Incoming Call ({appointments.filter((e) => e.status === 'booking').length})</Box>
				</Box>
				{appointments
					.filter((e) => e.status === 'booking')
					.map((row) => {
						const onClick = () => navigate('/case/' + row._id)
						const isActive = currentTime.isBetween(moment(row.telehealth_slot.start_date), moment(row.telehealth_slot.end_date))
						return (
							<Box
								key={row._id}
								mb={1}
								px={3}
								py={2}
								borderRadius="16px"
								border={`${
									currentTime.isBetween(moment(row.telehealth_slot.start_date), moment(row.telehealth_slot.end_date))
										? '4px solid #008281'
										: '1px solid #D8D8D8'
								}`}
								display="flex"
							>
								<PatientDetail {...row} isActive={isActive} currentTime={currentTime} onClick={onClick} buttonTitle={isActive ? 'JOIN' : 'DETAILS'} />
							</Box>
						)
					})}
			</Box>
		</Layout>
	)
}

export default Case
