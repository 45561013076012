import { drawerWidth } from 'src/Theme.js'
import { useAuth } from 'src/context/auth-context.js'
import AccountCircle from '@mui/icons-material/AccountCircle'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Drawer from 'src/components/layout/drawer'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, { useState } from 'react'
import Toolbar from '@mui/material/Toolbar'
import useLayoutStore from 'src/store/layout.js'

const setDrawerState = (state) => state.setDrawer

const Header = () => {
	const { user, logout } = useAuth()
	const isLoggedIn = Boolean(user?._id)
	const changeDrawerState = useLayoutStore(setDrawerState)
	const [anchorEl, setAnchorEl] = useState(null)

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<Box sx={{ display: 'flex' }}>
			<AppBar
				position="static"
				sx={{
					width: { lg: `calc(100% - ${drawerWidth}px)` },
					ml: { lg: `${drawerWidth}px` },
				}}
			>
				<Toolbar>
					<IconButton edge="start" color="inherit" aria-label="open drawer" onClick={() => changeDrawerState(true)} sx={{ display: { lg: 'none' } }}>
						<MenuIcon />
					</IconButton>
					<Box sx={{ flexGrow: 1 }} />
					{isLoggedIn && (
						<Box>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								color="inherit"
								onClick={handleMenu}
							>
								<AccountCircle />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem onClick={logout}>Logout</MenuItem>
							</Menu>
						</Box>
					)}
				</Toolbar>
			</AppBar>
			<Drawer />
		</Box>
	)
}

export default Header
