import { bootstrapAppData } from 'src/utils/bootstrap.js'
import { Player } from '@lottiefiles/react-lottie-player'
import { useAsync } from 'src/utils/hooks.js'
import * as auth from 'src/utils/auth-client.js'
import axios from 'src/Axios'
import Box from '@mui/material/Box'
import React, { createContext, useContext, useMemo, useEffect, useCallback } from 'react'

const AuthContext = createContext()
AuthContext.displayName = 'AuthContext'

function AuthProvider(props) {
	// eslint-disable-next-line
	const { data: user, status, error, isLoading, isIdle, isError, isSuccess, run, setData } = useAsync()

	useEffect(() => {
		const appDataPromise = bootstrapAppData()
		run(appDataPromise)
	}, [run])

	const login = useCallback(
		async (form) => {
			// COMMENT: ต้องได้ access, refresh, user กลับมา
			const response = await axios({
				method: 'POST',
				url: `api/auth/login`,
				data: {
					login_phone_number: form.login_phone_number,
					password: form.password,
				},
			})
			const access_token = response.data.access_token
			const refresh_token = response.data.refresh_token
			// COMMENT: save token to Local Storage.
			await auth.saveToken(access_token, refresh_token)
			// COMMENT: ยังไม่มี ต้องให้ท๊อปเพิ่ม
			const userResponse = await axios({
				method: 'GET',
				url: `api/user/profile`,
			})
			const user = userResponse.data.profile
			// user ...
			setData(user)
		},
		[setData]
	)

	const logout = useCallback(() => {
		auth.clearToken()
		setData(null)
	}, [setData])

	// COMMENT: reload user profile
	const refetchProfile = useCallback(async () => {
		const userResponse = await axios({
			method: 'GET',
			url: `api/user/profile`,
		})
		const user = userResponse.data.profile
		setData(user)
		// user ...
	}, [setData])

	const value = useMemo(() => ({ user, login, logout, refetchProfile }), [login, logout, user, refetchProfile])

	if (isLoading || isIdle) {
		return (
			<React.Fragment>
				<Box
					sx={{
						display: 'block',
						height: '100vh',
						// bgcolor: 'primary.main',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%',
						}}
					>
						<Player
							autoplay
							loop
							src="https://assets9.lottiefiles.com/private_files/lf30_3lsokhbd.json"
							style={{ height: '300px', width: '300px' }}
							// background="#008281"
							controls={false}
						/>
					</Box>
				</Box>
			</React.Fragment>
		)
	}

	if (isError) {
		return <div>full page error with ...</div>
	}

	if (isSuccess) {
		return <AuthContext.Provider value={value} {...props} />
	}
}

function useAuth() {
	const context = useContext(AuthContext)
	if (context === undefined) {
		throw new Error(`useAuth must be used within a AuthProvider`)
	}
	return context
}

export { AuthProvider, useAuth }
