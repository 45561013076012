import { getAccessToken, getRefreshToken, saveAccessToken, clearToken } from 'src/utils/auth-client.js'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_ENDPOINT

// *********************************
// Add a request interceptor - will move to some file(not here)
// *********************************
axios.interceptors.request.use(
	(config) => {
		// COMMENT: หา access_token, refresh_token
		const access_token = getAccessToken()
		const refresh_token = getRefreshToken()
		if (access_token) {
			config.headers['Authorization'] = `Bearer ${access_token}`
		}
		if (refresh_token) {
			config.headers['Refresh'] = refresh_token
		}
		config.headers['Content-Type'] = 'application/json'
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

// *********************************
// Add a response interceptor
// *********************************
axios.interceptors.response.use(
	(response) => {
		return response
	},
	async (error) => {
		const originalRequest = error.config
		if (error.response.status === 403 && !originalRequest._retry) {
			originalRequest._retry = true
			try {
				// COMMENT: request new access token
				const response = await ajax({
					method: 'POST',
					url: `api/auth/refresh`,
				})
				const access_token = response.data.access_token
				// COMMENT: save access_token to Local Storage.
				await saveAccessToken(access_token)
				return axios(originalRequest)
			} catch (error) {
				if (error.response.data.code === 401 && error.response.config.url === `${API_URL}/api/auth/refresh`) {
					// COMMENT: CLEAR TOKEN
					await clearToken()
				}
			}
		}
		return Promise.reject(error)
	}
)

const ajax = ({ url, ...props }) => {
	const options = {
		url: `${API_URL}/${url}`,
		headers: { 'Content-Type': 'application/json' },
		...props,
	}
	return axios(options)
}

export default ajax
