import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import React from 'react'
import get from 'lodash/get'
import moment from 'moment'
import MaleIcon from '@mui/icons-material/Male'
import FemaleIcon from '@mui/icons-material/Female'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import ScheduleIcon from '@mui/icons-material/Schedule'
import MaleImg from 'src/assets/images/MockupProfile_Telemed_Male.png'
import FemaleImg from 'src/assets/images/MockupProfile_Telemed_Female.png'

function Card(props) {
	const first_name = get(props, 'person.first_name')
	const last_name = get(props, 'person.last_name')
	const gender = get(props, 'person.gender')
	const start_date = get(props, 'telehealth_slot.start_date', null)
	const end_date = get(props, 'telehealth_slot.end_date', null)

	const currentTime = props.currentTime
	const isActive = props.isActive
	const onClick = props.onClick
	const disabled = props.disabled || false
	const buttonTitle = props.buttonTitle

	///
	// const currentTime = moment(start_date)
	// console.log(currentTime)
	// if (currentTime.isBetween(moment(start_date), moment(end_date))) {
	// 	console.log('is between')
	// } else {
	// 	console.log('is not between')
	// }
	// const [currentTime, setCurrentTime] = useState(moment())

	const Countdown = (start, end) => {
		if (moment(currentTime) <= moment(start)) {
			//return moment.duration(moment(start).diff(currentTime)).asSeconds()
			return (
				<Box fontSize={12} color="text.secondary">{`ใน ${moment
					.utc(moment.duration(moment(start).diff(currentTime)).asMilliseconds())
					.format('H [ชม.] m [น.] s [วินาที]')}`}</Box>
			)
		}

		if (moment(currentTime) >= moment(end)) {
			return (
				<Box fontSize={12} color="text.secondary">
					เกินเวลา
				</Box>
			)
		}

		if (isActive) {
			return (
				<Box fontSize={12} color="text.secondary">
					พร้อมเข้าห้อง
				</Box>
			)
		}
	}
	// useEffect(() => {
	// 	const interval = setInterval(() => setCurrentTime(moment()), 1000)
	// 	return () => {
	// 		clearInterval(interval)
	// 	}
	// }, [])

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs={12} md={6}>
				<Box display="flex" alignItems="center">
					<Avatar
						src={(gender === 'male' && MaleImg) || (gender === 'female' && FemaleImg) || null}
						sx={{
							border: '1px solid',
							borderColor: 'primary.main',
							p: 1,
							width: 85,
							height: 85,
						}}
					/>
					<Box ml={2}>
						<Box>{Countdown(start_date, end_date)}</Box>
						<Box fontWeight={600} fontSize={17}>
							{first_name} {last_name}
						</Box>
						<Box fontSize={12} color="text.secondary">
							{gender === 'male' && <MaleIcon sx={{ mb: -1, mr: 1 }} />}
							{gender === 'female' && <FemaleIcon sx={{ mb: -1, mr: 1 }} />}
							<span style={{ textTransform: 'capitalize' }}>{gender}</span>
						</Box>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box display="flex">
					<Box mr={4}>
						<Box fontSize={12} color="text.secondary">
							<CalendarTodayIcon sx={{ mb: -1, mr: 1 }} /> Date:
						</Box>
						<Box mt={1} color="primary.main">
							{moment(start_date).format('MMM DD, yyyy')}
						</Box>
					</Box>
					<Box>
						<Box mb={1} fontSize={12} color="text.secondary">
							<ScheduleIcon sx={{ mb: -1, mr: 1 }} /> Time:
						</Box>
						<Box color="primary.main">
							{moment(start_date).format('HH:mm')}-{moment(end_date).format('HH:mm')}
							น.
						</Box>
					</Box>
					<Box sx={{ ml: 'auto', mr: 2 }}>
						<Button variant={isActive ? 'contained' : 'outlined'} disabled={disabled} onClick={onClick}>
							{buttonTitle}
						</Button>
					</Box>
				</Box>
			</Grid>
		</Grid>
	)
}

export default Card
