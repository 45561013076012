import { OTPublisher, OTSubscriber, createSession } from 'opentok-react'
import { useElementSize } from 'usehooks-ts'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'src/Axios'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CallEndIcon from '@mui/icons-material/CallEnd'
import CheckIcon from '@mui/icons-material/Check'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from '@mui/icons-material/Close'
import debounce from 'debounce'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import Drawer from '@mui/material/Drawer'
import get from 'lodash/get'
import HealingIcon from '@mui/icons-material/Healing'
import IconButton from '@mui/material/IconButton'
import join from 'lodash/join'
import MasksIcon from '@mui/icons-material/Masks'
import MedicationIcon from '@mui/icons-material/Medication'
import MenuIcon from '@mui/icons-material/Menu'
import MicIcon from '@mui/icons-material/Mic'
import MicOffIcon from '@mui/icons-material/MicOff'
import moment from 'moment'
import NoteIcon from '@mui/icons-material/Note'
import Paper from '@mui/material/Paper'
import React, { useState, useEffect, useRef, Fragment } from 'react'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import TextField from '@mui/material/TextField'
import TimelineIcon from '@mui/icons-material/Timeline'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import VerifiedIcon from '@mui/icons-material/Verified'
import VideocamIcon from '@mui/icons-material/Videocam'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'

function Video() {
	// eslint-disable-next-line
	const navigate = useNavigate()
	// eslint-disable-next-line
	let [searchParams, setSearchParams] = useSearchParams()
	const [loading, setLoading] = useState(false)
	const [initialForm, setInitialForm] = useState(true)
	/**
	 * URL key to connect
	 */
	const room_key = searchParams.get('room_key')
	const room_id = searchParams.get('room_id')
	const session_id = searchParams.get('session_id')
	/**
	 * Video State & Control
	 */
	const [sessionHelper, setSessionHelper] = useState()
	const [streams, setStreams] = useState([])
	const publisherRef = useRef()
	const [caseNo, setCaseNo] = useState('')
	/**
	 * Drawer State
	 */
	const [drawer, setDrawer] = useState(false)
	const [tab, setTab] = useState('1')
	// AUTO-SAVE State
	const [saving, setSaving] = useState('initial')
	// eslint-disable-next-line
	const [drawerRef, { width, height }] = useElementSize()
	// eslint-disable-next-line
	const [error, setError] = useState(null)
	// eslint-disable-next-line
	const [connection, setConnection] = useState('Connecting')
	// eslint-disable-next-line
	const [audio, setAudio] = useState(true)
	// eslint-disable-next-line
	const [publishVideo, setPublishVideo] = useState(true)
	// incomplete button show/hide
	const [showIncompleteBtn, setShowIncompleteBtn] = useState(false)
	// COMMENT disconnected, connected, destroyed
	const [subscribeConnection, setSubscribeConnection] = useState('disconnected')
	const isSubscribeConnect = subscribeConnection === 'connected'
	// COMMENT: time remain
	const [endTime, setEndTime] = useState(null)
	// COMMENT: Form in drawer
	const { register, handleSubmit, setValue, getValues } = useForm()
	// check mobile screen
	const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'))
	// tab2 data to show
	const symptom = getValues('symptom')
	const duration = getValues('duration')
	const medication_used = getValues('medication_used')
	const congenital_disease = getValues('congenital_disease')
	const allergy = getValues('allergy')
	const frequently_medication_used = getValues('frequently_medication_used')
	const alcohol = getValues('alcohol')
	const smoking = getValues('smoking')
	const pregnant = getValues('pregnant')
	const breast_feeding = getValues('breast_feeding')
	// eslint-disable-next-line
	const calculateTimeLeft = () => {
		const _endTime = moment(endTime)
		const currentTime = moment()
		let result = _endTime.diff(currentTime)
		return Math.round(result / 60000)
	}

	const [remaining, setRemaining] = useState(calculateTimeLeft())
	// COMMENT style for video publisher when no subscriber
	const noSubStyle = { width: '100vw', height: '100vh', zIndex: 'modal' }
	const withSubStyle = { width: '161px', height: '214px', position: 'absolute', zIndex: 'modal', top: 20, left: 20 }

	let audioComp = null
	let publishComp = null

	let publisherEventHandlers = {
		accessDenied: () => {
			// console.log('User denied access to media source')
		},
		streamCreated: () => {
			// console.log('Publisher stream created')
		},
		streamDestroyed: ({ reason }) => {
			// console.log(`Publisher stream destroyed because: ${reason}`)
		},
	}

	let subscriberEventHandlers = {
		connected: (event) => {
			setSubscribeConnection('connected')
		},
		destroyed: (event) => {
			setSubscribeConnection('destroyed')
		},
		disconnected: (event) => {
			setSubscribeConnection('disconnected')
		},
		videoEnabled: () => {
			// console.log('Subscriber video enabled')
		},
		videoDisabled: () => {
			// console.log('Subscriber video disabled')
		},
	}

	const onPublish = async () => {
		// console.log('Publish Success')
		// Recording Video
		try {
			await axios({
				method: 'put',
				url: `api/appointment/${caseNo}/video-record`,
				data: {},
			})
		} catch (error) {
			// error ...
		}
	}

	const handleAudio = () => {
		setAudio((prevState) => !prevState)
	}

	const handleVideo = () => {
		setPublishVideo((prevState) => !prevState)
	}

	const onPublishError = (error) => {
		setError({ error })
	}

	const onSubscribe = () => {
		// console.log('Subscribe Success')
	}

	const handleToggleBtnClick = () => {
		setDrawer((prevState) => !prevState)
	}

	// eslint-disable-next-line
	const handleTabChange = (event, newValue) => {
		setTab(newValue)
	}

	const onSubmit = (data, event) => {
		event.preventDefault()
		// console.log(data)
	}

	const handleIncompleteClick = async () => {
		try {
			const connection_id = get(sessionHelper, 'connection.connectionId', '')
			await axios({
				method: 'put',
				url: `api/appointment/${caseNo}/incomplete`,
				data: { connection_id },
			})
			navigate({ pathname: '/', replace: true })
		} catch {
			//TODO: handle some error here.
		}
	}

	const handleEndCall = async () => {
		try {
			const connection_id = get(sessionHelper, 'connection.connectionId', '')
			await axios({
				method: 'put',
				url: `api/appointment/${caseNo}/finished-video`,
				data: { connection_id },
			})
			navigate({ pathname: `/summary/${caseNo}`, replace: true })
		} catch (error) {
			// handle some error here.
		}
	}

	const onChangeMedical = debounce(async (event) => {
		const medical_recommendation = event.target.value
		setValue('medical_recommendation', medical_recommendation)
		try {
			setSaving('saving')
			await axios({
				method: 'put',
				url: `api/appointment/${caseNo}/medical-recommendation`,
				data: { medical_recommendation },
			})
		} catch (error) {
		} finally {
			setSaving('saved')
		}
	}, 500)

	const onChangeSummaryNote = debounce(async (event) => {
		const summary_note = event.target.value
		setValue('summary_note', summary_note)
		try {
			setSaving('saving')
			await axios({
				method: 'put',
				url: `api/appointment/${caseNo}/summary-note`,
				data: { summary_note },
			})
		} catch (error) {
		} finally {
			setSaving('saved')
		}
	}, 500)

	const onChangePatientRecommendation = debounce(async (event) => {
		const patient_recommendation = event.target.value
		setValue('patient_recommendation', patient_recommendation)
		try {
			setSaving('saving')
			await axios({
				method: 'put',
				url: `api/appointment/${caseNo}/patient-recommendation`,
				data: { patient_recommendation },
			})
		} catch (error) {
		} finally {
			setSaving('saved')
		}
	}, 500)

	const onChangeDiagnosis = debounce(async (event) => {
		const diagnosis = event.target.value
		setValue('diagnosis', diagnosis)
		try {
			setSaving('saving')
			await axios({
				method: 'put',
				url: `api/appointment/${caseNo}/diagnosis`,
				data: { diagnosis },
			})
		} catch (error) {
		} finally {
			setSaving('saved')
		}
	}, 500)

	if (audio) {
		audioComp = (
			<Button variant="outlined" startIcon={<MicIcon />} onClick={handleAudio} sx={{ mr: 1, my: 1 }}>
				Mute
			</Button>
		)
	} else {
		audioComp = (
			<Button variant="outlined" startIcon={<MicOffIcon />} onClick={handleAudio} sx={{ mr: 1, my: 1 }}>
				Unmute
			</Button>
		)
	}

	if (publishVideo) {
		publishComp = (
			<Button variant="outlined" startIcon={<VideocamIcon />} onClick={handleVideo} sx={{ mr: 1, my: 1 }}>
				Camera off
			</Button>
		)
	} else {
		publishComp = (
			<Button variant="outlined" startIcon={<VideocamOffIcon />} onClick={handleVideo} sx={{ mr: 1, my: 1 }}>
				Camera on
			</Button>
		)
	}

	// Connect Session when component mount
	// sessionhelper create session
	useEffect(() => {
		setLoading(true)
		try {
			const session = createSession({
				apiKey: room_key,
				sessionId: room_id,
				token: session_id,
				onStreamsUpdated: (streams) => {
					setStreams(streams)
					// console.log('Current subscriber streams:', streams)
				},
			})
			setSessionHelper(session.session)
		} catch (error) {
		} finally {
			setLoading(false)
		}
		return () => {
			try {
				sessionHelper.disconnect()
			} catch (error) {}
		}
	}, [room_key, room_id, session_id, sessionHelper])

	useEffect(() => {
		if (typeof sessionHelper === 'object') {
			sessionHelper?.on('sessionConnected', (event) => {
				const data = get(event, 'target.connection.data', null)
				const dataParsed = JSON.parse(data)
				const end_date = get(dataParsed, 'end_date', null)
				const case_no = get(dataParsed, '_id', null)
				setCaseNo(case_no)
				setEndTime(end_date)
			})
		} else {
			return
		}
	}, [sessionHelper])

	useEffect(() => {
		let timer
		const _endTime = moment(endTime)
		const currentTime = moment()
		let result = _endTime.diff(currentTime)
		if (!isNaN(result)) {
			timer = setTimeout(() => {
				navigate({ pathname: '/', replace: true })
			}, result)
		}
		return () => {
			clearTimeout(timer)
		}
	}, [endTime, navigate])

	// update remaining time
	useEffect(() => {
		const timer = setInterval(() => {
			if (calculateTimeLeft() <= 5) {
				setShowIncompleteBtn(true)
			}
			setRemaining(calculateTimeLeft())
		}, 1000)
		return () => {
			clearInterval(timer)
		}
	}, [setRemaining, calculateTimeLeft])

	useEffect(() => {
		async function initialForm(caseId) {
			try {
				// set form loading = true
				setInitialForm(true)
				if (caseId) {
					const resp = await axios({ method: 'get', url: `api/appointment/${caseId}` })
					const summary_note = get(resp, 'data.appointment.summary_note', '')
					const patient_recommendation = get(resp, 'data.appointment.patient_recommendation', '')
					const medical_recommendation = get(resp, 'data.appointment.medical_recommendation', '')
					const diagnosis = get(resp, 'data.appointment.diagnosis', '')
					const symptom = get(resp, 'data.appointment.symptom', '')
					const duration = get(resp, 'data.appointment.duration', '')
					const medication_used = get(resp, 'data.appointment.medication_used', '')
					const congenital_disease = get(resp, 'data.appointment.person.health_profile.congenital_disease', '')
					const _allergy = get(resp, 'data.appointment.person.health_profile.allergy', '')
					const allergy_txt = join(_allergy, ',')
					const frequently_medication_used = get(resp, 'data.appointment.person.health_profile.medication_used', '')
					const alcohol = get(resp, 'data.appointment.person.health_profile.alcohol', false)
					const smoking = get(resp, 'data.appointment.person.health_profile.smoking', false)
					const pregnant = get(resp, 'data.appointment.person.health_profile.pregnant', false)
					const breast_feeding = get(resp, 'data.appointment.person.health_profile.breast_feeding', false)
					setValue('summary_note', summary_note)
					setValue('patient_recommendation', patient_recommendation)
					setValue('medical_recommendation', medical_recommendation)
					setValue('diagnosis', diagnosis)
					setValue('symptom', symptom)
					setValue('duration', duration)
					setValue('medication_used', medication_used)
					setValue('congenital_disease', congenital_disease)
					setValue('allergy', allergy_txt)
					setValue('frequently_medication_used', frequently_medication_used)
					setValue('alcohol', alcohol)
					setValue('smoking', smoking)
					setValue('pregnant', pregnant)
					setValue('breast_feeding', breast_feeding)
				}
			} catch (error) {
			} finally {
				// set form loading = false
				setInitialForm(false)
			}
		}
		initialForm(caseNo)
	}, [caseNo, setInitialForm, setValue])

	if (loading) {
		return <div>Loading...</div>
	}

	return (
		<Box>
			<Drawer anchor="right" sx={{ width: 425 }} open={drawer} onClose={() => {}}>
				<Box sx={{ width: 425 }} ref={drawerRef}>
					<TabContext value={tab}>
						<TabList onChange={handleTabChange} aria-label="summary-report">
							<Tab label="Summary Note" value="1" />
							<Tab label="Patient" value="2" />
						</TabList>
						<form onSubmit={handleSubmit(onSubmit)}>
							<TabPanel value="1">
								<Fragment>
									<Box>
										{initialForm && <Box>Loading...</Box>}
										<Typography variant="h6" gutterBottom component="span">
											Personal Note
										</Typography>
										<TextField
											{...register('summary_note', { required: true })}
											onChange={onChangeSummaryNote}
											id="personal-note-input"
											label="Personal Note"
											fullWidth
											multiline
											minRows={2}
											maxRows={5}
											autoComplete="off"
											sx={{ mt: 1, mb: 1.5 }}
											variant="standard"
											disabled={initialForm}
											InputLabelProps={{ shrink: true }}
										/>
									</Box>
									<Box>
										<Typography variant="h6" gutterBottom component="span">
											Case Summary
										</Typography>
										<TextField
											{...register('patient_recommendation', { required: true })}
											onChange={onChangePatientRecommendation}
											id="patient-recommendation-input"
											label="Patient Recommendation"
											fullWidth
											variant="standard"
											sx={{ mb: 1.5 }}
											disabled={initialForm}
											InputLabelProps={{ shrink: true }}
										/>
										<TextField
											{...register('medical_recommendation', { required: true })}
											onChange={onChangeMedical}
											id="medical-recommendation-input"
											label="Medical Recommendation"
											fullWidth
											variant="standard"
											sx={{ mb: 1.5 }}
											disabled={initialForm}
											InputLabelProps={{ shrink: true }}
										/>
										<TextField
											{...register('diagnosis', { required: true })}
											onChange={onChangeDiagnosis}
											id="diagnosis-input"
											label="Consultation reports"
											fullWidth
											variant="standard"
											sx={{ mb: 1.5 }}
											disabled={initialForm}
											InputLabelProps={{ shrink: true }}
										/>
									</Box>
									<Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
										{saving === 'saving' && (
											<Chip icon={<CircularProgress sx={{ mr: 1 }} size={20} />} label={<Box sx={{ color: 'white' }}>Saving…</Box>} color="primary" />
										)}
										{saving === 'saved' && <Chip icon={<CheckIcon />} label={<Box sx={{ color: 'white' }}>Saved</Box>} color="success" />}
									</Box>
								</Fragment>
							</TabPanel>
							<TabPanel value="2">
								<Stack spacing={2}>
									<Box display="flex">
										<Paper elevation={0}>
											<MasksIcon fontSize="16" />
											<Box sx={{ display: 'inline', ml: 1, fontSize: 16 }}>อาการ:</Box>
											<Box sx={{ typography: 'caption' }}>{symptom}</Box>
										</Paper>
									</Box>
									<Box display="flex">
										<Paper elevation={0}>
											<TimelineIcon fontSize="16" />
											<Box sx={{ display: 'inline', ml: 1, fontSize: 16 }}>ระยะเวลาที่เป็น:</Box>
											<Box sx={{ typography: 'caption' }}>{duration}</Box>
										</Paper>
									</Box>
									<Box display="flex">
										<Paper elevation={0}>
											<ReportProblemIcon fontSize="16" />
											<Box sx={{ display: 'inline', ml: 1, fontSize: 16 }}>ยาที่ทานไปแล้ว:</Box>
											<Box sx={{ typography: 'caption' }}>{medication_used}</Box>
										</Paper>
									</Box>
									<Box display="flex">
										<Paper elevation={0}>
											<HealingIcon fontSize="16" />
											<Box sx={{ display: 'inline', ml: 1, fontSize: 16 }}>โรคประจำตัว:</Box>
											<Box sx={{ typography: 'caption' }}>{congenital_disease}</Box>
										</Paper>
									</Box>
									<Box display="flex">
										<Paper elevation={0}>
											<DragHandleIcon fontSize="14" />
											<Box sx={{ display: 'inline', ml: 1, fontSize: 14 }}>การแพ้ยา:</Box>
											<Box>{allergy}</Box>
										</Paper>
									</Box>
									<Box display="flex">
										<Paper elevation={0}>
											<MedicationIcon fontSize="14" />
											<Box sx={{ display: 'inline', ml: 1, fontSize: 14 }}>ยาที่ทานประจำ:</Box>
											<Box>{frequently_medication_used}</Box>
										</Paper>
									</Box>
									<Box display="flex">
										<Paper elevation={0}>
											<NoteIcon fontSize="14" />
											<Box sx={{ display: 'inline', ml: 1, fontSize: 14 }}>อื่นๆ:</Box>
											<Box>
												{alcohol && (
													<>
														<VerifiedIcon sx={{ mb: -0.75, ml: 1, color: 'primary.main' }} />
														ดื่มเหล้า
													</>
												)}
												{smoking && (
													<>
														<VerifiedIcon sx={{ mb: -0.75, ml: 1, color: 'primary.main' }} />
														สูบบุหรี่
													</>
												)}
												{pregnant && (
													<>
														<VerifiedIcon sx={{ mb: -0.75, ml: 1, color: 'primary.main' }} />
														ตั้งครรถ์
													</>
												)}
												{breast_feeding && (
													<>
														<VerifiedIcon sx={{ mb: -0.75, ml: 1, color: 'primary.main' }} />
														ให้นมบุตร
													</>
												)}
												{/* <Box sx={{ display: 'inline', mx: 1, fontSize: 14 }}>
													<VerifiedIcon fontSize="14" color={pregnant ? 'success' : 'inherit'} />
													ตั้งครรถ์
												</Box> */}
											</Box>
										</Paper>
									</Box>
								</Stack>
							</TabPanel>
						</form>
					</TabContext>
				</Box>
			</Drawer>
			{/* {console.log('Rendered Unstable component')} */}
			<div>
				<Box
					sx={{
						position: 'absolute',
						zIndex: 'modal',
						top: 54,
						right: drawer ? width + 54 : 54,
						bgcolor: 'background.paper',
						borderRadius: '50%',
						border: 1,
						borderColor: 'common.black',
					}}
				>
					<IconButton aria-label="toggle" onClick={handleToggleBtnClick}>
						{drawer ? <CloseIcon sx={{ color: 'common.black' }} /> : <MenuIcon sx={{ color: 'common.black' }} />}
					</IconButton>
				</Box>
				{sessionHelper && (
					<Box sx={{ position: 'relative' }}>
						<Box id="publisher" sx={isSubscribeConnect ? withSubStyle : noSubStyle}>
							<OTPublisher
								style={isSubscribeConnect ? { height: '214px', borderRadius: '4px' } : { height: '100vh' }}
								properties={{
									publishVideo,
									publishAudio: audio,
									showControls: false,
									width: '100%',
									height: '100%',
								}}
								session={sessionHelper}
								onPublish={onPublish}
								onError={onPublishError}
								eventHandlers={publisherEventHandlers}
								ref={publisherRef}
							/>
						</Box>
						<Box id="subscriber" sx={{ width: '100vw', height: '100vh', bgcolor: 'info.main', display: !isSubscribeConnect ? 'none' : 'block' }}>
							{streams.map((stream) => {
								return (
									<OTSubscriber
										properties={{ width: '100%', height: '100vh', showControls: false }}
										session={sessionHelper}
										key={stream.id}
										stream={stream}
										onSubscribe={onSubscribe}
										// onError={onSubscribeError}
										eventHandlers={subscriberEventHandlers}
									/>
								)
							})}
						</Box>
					</Box>
				)}
			</div>
			<Box
				sx={{
					position: 'absolute',
					zIndex: 'modal',
					width: '100%',
					bottom: 0,
					px: matches ? 1 : 3,
					py: matches ? 1 : 4,
					bgcolor: 'secondary.main',
					textAlign: matches ? 'center' : 'left',
				}}
			>
				<Button variant="outlined" sx={{ mr: 1, my: 1 }} disableRipple>
					เหลือเวลาอีก {remaining} นาที
				</Button>
				{audioComp}
				{publishComp}
				{showIncompleteBtn && (
					<Box sx={{ display: 'inline' }}>
						<Button variant="contained" color="warning" sx={{ mr: 1, my: 1, color: 'common.white' }} onClick={handleIncompleteClick}>
							Incomplete
						</Button>
					</Box>
				)}
				<Box sx={{ display: 'inline' }}>
					<Button variant="contained" startIcon={<CallEndIcon />} color="error" sx={{ my: 1 }} onClick={handleEndCall}>
						END ROOM
					</Button>
				</Box>
			</Box>
		</Box>
	)
}
export default Video

// <Tab label="Rx" value="3" />
// <TabPanel value="3">Item Three</TabPanel>
