import Box from '@mui/material/Box'
import React from 'react'
import Layout from 'src/components/layout'

import PrescriptionItem from 'src/components/prescription/item.detail.card'

function Demo() {
	return (
		<Layout>
			<Box p={4}>
				<Box mb={5}>
					<Box fontSize={14}>Demo</Box>
				</Box>
				<PrescriptionItem onClick={() => {}} name="Trade Name" line1="generic" line2="ทานครั้งละ 2 เม็ด เช้า กลางวัน เย็น" package_detail="Package" />
				<PrescriptionItem />
				<PrescriptionItem />
			</Box>
		</Layout>
	)
}

export default Demo
