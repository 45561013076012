import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import { useSchedule } from 'src/context/schedule-context.js'
import Collapse from '@mui/material/Collapse'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import moment from 'moment'
import LoadingButton from '@mui/lab/LoadingButton'
import Chip from '@mui/material/Chip'

const ExpandMore = styled((props) => {
	const { expand, ...other } = props
	return <IconButton {...other} />
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}))

const morning_slots = [
	'06:00 - 06:20',
	'06:20 - 06:40',
	'06:40 - 07:00',
	'07:00 - 07:20',
	'07:20 - 07:40',
	'07:40 - 08:00',
	'08:00 - 08:20',
	'08:20 - 08:40',
	'08:40 - 09:00',
	'09:00 - 09:20',
	'09:20 - 09:40',
	'09:40 - 10:00',
	'10:00 - 10:20',
	'10:20 - 10:40',
	'10:40 - 11:00',
	'11:00 - 11:20',
	'11:20 - 11:40',
	'11:40 - 12:00',
]

const noon_slots = [
	'12:00 - 12:20',
	'12:20 - 12:40',
	'12:40 - 13:00',
	'13:00 - 13:20',
	'13:20 - 13:40',
	'13:40 - 14:00',
	'14:00 - 14:20',
	'14:20 - 14:40',
	'14:40 - 15:00',
	'15:00 - 15:20',
	'15:20 - 15:40',
	'15:40 - 16:00',
	'16:00 - 16:20',
	'16:20 - 16:40',
	'16:40 - 17:00',
	'17:00 - 17:20',
	'17:20 - 17:40',
	'17:40 - 18:00',
]

const evening_slots = [
	'18:00 - 18:20',
	'18:20 - 18:40',
	'18:40 - 19:00',
	'19:00 - 19:20',
	'19:20 - 19:40',
	'19:40 - 20:00',
	'20:00 - 20:20',
	'20:20 - 20:40',
	'20:40 - 21:00',
	'21:00 - 21:20',
	'21:20 - 21:40',
	'21:40 - 22:00',
	'22:00 - 22:20',
	'22:20 - 22:40',
	'22:40 - 23:00',
	'23:00 - 23:20',
	'23:20 - 23:40',
	'23:40 - 00:00',
]

const dawn_slots = [
	'00:00 - 00:20',
	'00:20 - 00:40',
	'00:40 - 01:00',
	'01:00 - 01:20',
	'01:20 - 01:40',
	'01:40 - 02:00',
	'02:00 - 02:20',
	'02:20 - 02:40',
	'02:40 - 03:00',
	'03:00 - 03:20',
	'03:20 - 03:40',
	'03:40 - 04:00',
	'04:00 - 04:20',
	'04:20 - 04:40',
	'04:40 - 05:00',
	'05:00 - 05:20',
	'05:20 - 05:40',
	'05:40 - 06:00',
]

export default function CreateDialog({ open, defaultData, onClose, refetch }) {
	const handleClose = () => {
		onClose()
	}

	const [morning, setMorning] = useState(false)
	const [noon, setNoon] = useState(false)
	const [evening, setEvening] = useState(false)
	const [dawn, setDawn] = useState(false)
	const [startDate, setStartDate] = useState(moment())
	const [endDate, setEndDate] = useState(null)
	const [slots, setSlots] = useState([])

	const { createScheduleMultiple } = useSchedule()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		////// initialize when you want to create single
		if (defaultData) {
			setStartDate(moment(defaultData))

			const startTime = moment(defaultData).format('HH:mm')
			const endTime = moment(defaultData).add(20, 'minutes').format('HH:mm')
			const combineTime = `${startTime} - ${endTime}`
			setSlots([combineTime])
			morning_slots.includes(combineTime) && setMorning(false)
			noon_slots.includes(combineTime) && setNoon(true)
			evening_slots.includes(combineTime) && setEvening(true)
			dawn_slots.includes(combineTime) && setDawn(true)
		}
		////// when closed
		if (!open) {
			setMorning(false)
			setNoon(false)
			setEvening(false)
			setDawn(false)
			setStartDate(moment())
			setEndDate(null)
			setSlots([])
		}
		// WARNING: react-hooks/exhaustive-deps
		// eslint-disable-next-line
	}, [open])

	const onSelectSlots = (slot) => () => {
		if (!slots.includes(slot)) {
			setSlots([...slots, slot])
		} else {
			const temp = slots.filter((_slot) => _slot !== slot)
			setSlots(temp)
		}
	}

	const onCreate = async () => {
		setLoading(true)
		try {
			let data = {
				start_date: startDate.format('yyyy-MM-DD'),
				slots: slots.map((slot) => ({ time: slot.split(' ')[0] + ':00' })),
				// if no enddate use startdate instead
				end_date: endDate ? endDate.format('yyyy-MM-DD') : startDate.format('yyyy-MM-DD'),
			}

			await createScheduleMultiple(data)
			refetch()
			handleClose()
		} catch (error) {}
		setLoading(false)
	}

	const SlotTable = ({ title, timePeriod, setTimePeriod, time_slots }) => {
		const found = time_slots.some((r) => slots.indexOf(r) >= 0)

		return (
			<Box component={Paper} px={3} pt={1} pb={timePeriod ? 3 : 1} mb={timePeriod ? 1.5 : 0}>
				<Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
					<Box>
						{title} {found && <Chip label="Selected" color="success" />}
					</Box>{' '}
					<ExpandMore expand={timePeriod} onClick={() => setTimePeriod(!timePeriod)} aria-expanded={timePeriod} aria-label="show more">
						<ExpandMoreIcon />
					</ExpandMore>
				</Box>
				<Collapse in={timePeriod} timeout="auto" unmountOnExit>
					<Grid container spacing={1}>
						{time_slots.map((t) => (
							<Grid key={t} item xs={4}>
								<Button fullWidth variant={slots.includes(t) ? 'contained' : 'outlined'} onClick={onSelectSlots(t)}>
									{t}น.
								</Button>
							</Grid>
						))}
					</Grid>
				</Collapse>
			</Box>
		)
	}

	return (
		<div>
			<Dialog maxWidth="md" open={open} onClose={onClose}>
				<DialogTitle>Design Your Schedule</DialogTitle>
				<DialogContent>
					<DialogContentText>
						กำหนดตารางเวลาที่สะดวกของคุณ สำหรับการให้คำปรึกษา, คุณหมอสามารถเลือกช่วงวันที่และเวลาที่สะดวก หรือหากต้องการกำหนดวันที่สะดวกเพียงวันเดียว
						เลือกวันที่เริ่มต้น
					</DialogContentText>
					<Box mt={3}>
						<Box mb={2}>กำหนดวัน</Box>
						<Stack spacing={3} direction="row">
							<DesktopDatePicker
								label="Start Date*"
								inputFormat="DD/MM/yyyy"
								value={startDate}
								onChange={(event) => {
									const date = moment(event)
									setStartDate(date)
								}}
								renderInput={(params) => <TextField {...params} />}
							/>
							<DesktopDatePicker
								label="End Date"
								inputFormat="DD/MM/yyyy"
								value={endDate}
								onChange={(event) => {
									const date = moment(event)
									setEndDate(date)
								}}
								renderInput={(params) => <TextField {...params} />}
							/>
						</Stack>
					</Box>
					<Box my={2}>กำหนดช่วงเวลา</Box>
					<Box p={2} bgcolor={'#F2F2F2'}>
						<SlotTable title="ช่วงเช้า" timePeriod={morning} setTimePeriod={setMorning} time_slots={morning_slots} />
						<SlotTable title="ช่วงบ่าย" timePeriod={noon} setTimePeriod={setNoon} time_slots={noon_slots} />
						<SlotTable title="ช่วงค่ำ" timePeriod={evening} setTimePeriod={setEvening} time_slots={evening_slots} />
						<SlotTable title="ช่วงเช้ามืด" timePeriod={dawn} setTimePeriod={setDawn} time_slots={dawn_slots} />
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>cancel</Button>
					<LoadingButton loading={loading} onClick={onCreate} autoFocus>
						create
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</div>
	)
}
