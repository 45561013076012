import { useForm } from 'react-hook-form'
import axios from 'src/Axios'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import get from 'lodash/get'
import Grid from '@mui/material/Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import MenuItem from '@mui/material/MenuItem'
import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

const defaultValues = {
	supply: '',
	presentation_quantity: 0,
	presentation_name: '-',
	presentation_unit_quantity: 0,
	presentation_unit: '-',
	indication: '',
	administration: '',
}

function SupplyModal() {
	const { watch, register, setValue, handleSubmit, reset } = useForm({
		defaultValues,
	})
	const [showModal, setShowModal] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const { presentation_name, presentation_unit } = watch()

	// Dropdown Preparing
	const [presentationData, setPresentationData] = useState([])

	const toggleModal = () => {
		setShowModal((prev) => !prev)
	}

	const handleChangePresentationName = (e) => {
		setValue('presentation_name', e.target.value)
	}

	const handleChangePresentationUnit = (e) => {
		setValue('presentation_unit', e.target.value)
	}

	const onSubmit = async (data, event) => {
		setSubmitting(true)
		console.log(data)
		setSubmitting(false)
	}

	const handleCancelAddSupply = () => {
		reset()
		setShowModal(false)
	}

	useEffect(() => {
		register('presentation_name', { required: true })
		register('presentation_unit')
	}, [register])

	useEffect(() => {
		async function initialPresentationDropdown() {
			const resp = await axios({ method: 'get', url: `api/presentation-name` })
			const data = get(resp, 'data.presentation_name', [])
			setPresentationData(data)
		}
		initialPresentationDropdown()
	}, [])

	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Button variant="outlined" onClick={toggleModal}>
					open modal form
				</Button>
				<Dialog component="form" maxWidth="sm" fullWidth open={showModal} onClose={() => {}}>
					<DialogTitle>ADD MEDICAL SUPPLY</DialogTitle>
					<DialogContent>
						<Box mt={2}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										{...register('supply', { required: true })}
										label="Product Name *"
										InputLabelProps={{ shrink: true }}
										autoComplete="off"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} md={2}>
									<TextField
										{...register('presentation_quantity', { required: true })}
										label="Amount *"
										type="number"
										InputLabelProps={{ shrink: true }}
										inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
										autoComplete="off"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<TextField label="Presentation *" id="select-presentation" value={presentation_name} onChange={handleChangePresentationName} select fullWidth>
										<MenuItem value="-">
											<em>-</em>
										</MenuItem>
										{presentationData.map((record) => (
											<MenuItem key={record._id} value={record.name}>
												{record.name}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item xs={12} md={2}>
									<TextField
										{...register('presentation_unit_quantity', { required: true })}
										label="Amount"
										type="number"
										InputLabelProps={{ shrink: true }}
										inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
										autoComplete="off"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<TextField
										label="Presentation"
										id="select-presentation-unit"
										value={presentation_unit}
										onChange={handleChangePresentationUnit}
										select
										fullWidth
									>
										<MenuItem value="-">
											<em>-</em>
										</MenuItem>
										{presentationData.map((record) => (
											<MenuItem key={record._id} value={record.name}>
												{record.name}
											</MenuItem>
										))}
									</TextField>
								</Grid>
							</Grid>
							<Box mt={2}>
								<Typography variant="subtitle1" display="block" gutterBottom>
									Medication Uasge
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<TextField
											{...register('indication', { required: true })}
											label="Indication *"
											InputLabelProps={{ shrink: true }}
											fullWidth
											autoComplete="off"
											helperText="A medical condition that a medicine is used for."
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											{...register('administration', { required: true })}
											label="Suggestion *"
											InputLabelProps={{ shrink: true }}
											fullWidth
											autoComplete="off"
										/>
									</Grid>
								</Grid>
							</Box>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCancelAddSupply}>Cancel</Button>
						<LoadingButton loading={submitting} type="submit">
							Add
						</LoadingButton>
					</DialogActions>
				</Dialog>
			</form>
		</div>
	)
}

export default SupplyModal
