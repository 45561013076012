export const ACCESS_TOKEN_LOCAL_STORAGE_KEY = '__wtnv_auth_provider_access_token__'
export const REFRESH_TOKEN_LOCAL_STORAGE_KEY = '__wtnv_auth_provider_refresh_token__'

function getAccessToken() {
	return window.localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY)
}

function getRefreshToken() {
	return window.localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY)
}

async function saveAccessToken(token) {
	window.localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, token)
	return Promise.resolve()
}

async function saveToken(access_token, refresh_token) {
	window.localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, access_token)
	window.localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, refresh_token)
	return Promise.resolve()
}

async function clearToken() {
	window.localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY)
	window.localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY)
	return Promise.resolve()
}

export { getAccessToken, getRefreshToken, saveAccessToken, saveToken, clearToken }
