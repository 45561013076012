import Button from '@mui/material/Button'
import get from 'lodash/get'
import Grid from '@mui/material/Grid'
import React from 'react'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

const compoundGenerics = (data) => {
	const dataString = data.map((record) => `${get(record, 'name', '')} ${get(record, 'strength', '')}`)
	const compoundString = dataString.join(' ')
	return compoundString
}

function MedicineCard(props) {
	const { local_name, generics, presentation_name, presentation_unit, quantity, onSelect, disabled } = props

	const matches = useMediaQuery((theme) => theme.breakpoints.up('lg'))
	const genericsText = compoundGenerics(generics)

	return (
		<React.Fragment>
			<Grid item xs={12} lg={9}>
				<Typography variant="body1" gutterBottom component="div">
					{local_name}
				</Typography>
				<Typography variant="body2" gutterBottom component="div">
					{genericsText}
				</Typography>
				<Typography variant="body1" gutterBottom component="div">
					1 {presentation_name} x {quantity} {presentation_unit}
				</Typography>
			</Grid>
			<Grid item xs={12} lg={2}>
				<Button variant="text" fullWidth={matches} onClick={onSelect} disabled={disabled}>
					{disabled ? 'Selected' : 'Select'}
				</Button>
			</Grid>
		</React.Fragment>
	)
}

// 1 ${presentation_name} x ${quantity} ${presentation_unit}

MedicineCard.defaultProps = {
	local_name: '',
	generics: [],
	presentation_name: '',
	presentation_unit: '',
	quantity: 0,
	disabled: false,
	onSelect: () => {},
}

export default MedicineCard
