import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function CustomizedSnackbars({ errorText, onClose }) {
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		onClose()
	}

	return (
		<Snackbar open={!!errorText} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
			<Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
				{errorText}
			</Alert>
		</Snackbar>
		// <Alert severity="error">This is an error message!</Alert>
		// <Alert severity="warning">This is a warning message!</Alert>
		// <Alert severity="info">This is an information message!</Alert>
		// <Alert severity="success">This is a success message!</Alert>
	)
}
