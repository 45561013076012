import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import React from 'react'

function Card({ onClick, name, line1, line2, package_detail }) {
	const MainContent = (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs={12} sm={6}>
				<Box>{name}</Box>
				<Box color="text.secondary">{line1}</Box>
				<Box fontSize={14} color="text.secondary">
					{line2}
				</Box>
			</Grid>
			<Grid item xs={12} sm={4}>
				<Box>{package_detail}</Box>
			</Grid>
			<Grid item xs={12} sm={2} align="center">
				<Button onClick={onClick}>Remove</Button>
			</Grid>
		</Grid>
	)

	return (
		<Box px={4} mb={1.5}>
			{MainContent}
			<Divider sx={{ my: 1.5 }} />
		</Box>
	)
}

Card.defaultProps = {
	onClick: () => {},
	name: 'Trade Name',
	line1: 'generic',
	line2: 'ทานครั้งละ 2 เม็ด เช้า กลางวัน เย็น',
	package_detail: 'Package',
}

export default Card
